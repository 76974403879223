import React from "react";
import NavigationAnganwadi from "../../Components/Navigation/NavigationAnganwadi";

const AnaganwadiRoot = () => {
  return (
    <>
      <NavigationAnganwadi />
    </>
  );
};

export default AnaganwadiRoot;
