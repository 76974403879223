export const filterData = [
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
  },
  {
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "SRC NHS BHANTIGRAMA",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "ODUAL PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PATAPUR DEULI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PAIKSAHI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "OHALA NODAL UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "TIHULA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "LAXMINRUSINGH ADARSHA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "RESINGA UGUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "TOTASAHI PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "KASIASASAN PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "SAMBHU BHARATIPATNA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "KAMADARI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SALANGA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SADERIA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "PIPALIA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "OLAMARA PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "RATILO UGUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "MUKTESWAR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "NAGABASTA NHS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALAANDHIA PROJECT UP SCHOOL",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALATANALA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "LAMBILO PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "SRIMUKHA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "THAKURPATNA CPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANKHAPADA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "KALAPANCHANA URDU PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANDHAPUR PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "PURUSOTTAM NODAL UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "UMAMAHESWARPUR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "JAYADURGA NODAL UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TORIHANBANDHA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KUANARPUR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KALIAPADA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KASIPUR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TELISAHI PPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "SAINSA SASAN CPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "KHELAR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "PURUSANDHA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SALAJANGA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SAMARAISHASAN PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NAKADIHA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "MURUDI PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NUAPATNA PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KHEDARAPUR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "VILLIGRAM PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "JHAMPULAKUDA PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KARANJISAHI PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "MOHANDAS PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "SARBAPADA PRADHANSAHI PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "PODANA TUANSAHI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "JANARA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "NILAKANTHESWAR NODAL UP SCHOOL",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NAHANTARA CPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NILAKANTHA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "TARADPADA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "UHANGA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "KOPINDAPATALIGRAM PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "TERUNDIA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "RAJARASI PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "JAGABANDHU UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "KURUKUNDA PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "PADA GUATIRA PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JAINABAD CPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "NIMAPARA BAZAR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PANCHAYAT NODAL UP SCHOOL, PATAPUR",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PRACTISING UGUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JANATA NODAL UP SCHOOL",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "SUNINDA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOLHANA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "JAYADURGA NODAL UPS, CHAKRAKUDA",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOTHAKUSANGA CPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "PALASUDHA PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATKABAD PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATAPURI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "VILISASAN PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MITEIPUR PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MAHALO PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MALIASAHI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "TADHANA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MANIADIHA UGUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "TENTULIGUAN UGUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "JORAKULA PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ODAGUAN PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "PANCHAYAT NODAL UPS, RENGHALO",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "JAINABAD PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KERENDIABINDHA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "RENGHALO HINDU PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "OLIKANA UGUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADADEULI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KANAPUR NODAL UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "PABITRA HIGH SCHOOL",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUMARPADA NODAL UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "JUANLA BADHEISAHI PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KAJIPATANA PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "NIJOGAKASHOTI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUSIKONA CPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "KHARIKUDA MADHYASAHI PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "NUASANTHA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SANDHAGORADA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SAHADAPADA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "UCHHUPUR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SERAGARH BASTI PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "SUNUGORADI NODAL UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RENCH SASAN CPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RAIDBAZAR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "LOKEIGORADA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KHAJAPUR PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "MATHASAHI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KAJIPUR PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "NARAYANI PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "OLANSHA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KAHNAPUR PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KANTIA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "JAJALPUR PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "KUSUMATHENGA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "NUAGAON PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "ORAPAT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "TULASIPUR CPS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
  },
  {
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS",
  },
];
