import React from "react";
import NavigationPrakashak from "../../Components/Navigation/NavigationPrakashak";

const PrakashakRoot = () => {
  return (
    <>
      <NavigationPrakashak />
    </>
  );
};

export default PrakashakRoot;
