import React from "react";
import NavigationSchool from "../../Components/Navigation/NavigationSchool";

const SchoolRoot = () => {
  return (
    <>
      <NavigationSchool />
    </>
  );
};

export default SchoolRoot;
