import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  CircularProgress,
  Modal,
  Typography,
  TableContainer,
  Paper,
} from "@mui/material";
import Card from "../../../ReusableComponents/Card";
import PeopleIcon from "@mui/icons-material/People";
import Box from "@mui/material/Box";
import Api from "../../../Environment/PrakashakAPI";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";
import Nodata from "../../../Assets/Nodata.gif";
import defaultImage from "../../../Assets/default.jpg";
import Chart from "chart.js/auto";
import DynamicModal from "../../../Components/DynamicModal";
import SelectYear from "../../../ReusableComponents/SelectYear";
import { filterData } from "../../../downloads/jsonData";

const Schoolwise_performance = () => {
  const chartRef = useRef(null);
  const [loading, setLoading] = useState(false);
  // const [year, setYear] = useState("2024");
  const [districts, setDistricts] = useState("");
  const [data, setData] = useState([]);

  console.log("data--->", data);
  const [selectedMonth, setSelectedMonth] = useState(6);
  const [selectedWeek, setSelectedWeek] = useState(1);
  const [districtArr, setDistrictArr] = useState([]);
  const [blocks, setBlocks] = useState("");
  console.log("blocks---->", blocks);
  const [blockArr, setBlockArr] = useState([]);
  const [clusters, setCluseters] = useState("");
  const [clusterArr, setClusterArr] = useState([]);
  const [schools, setSchools] = useState("");
  const [schoolArr, setSchoolArr] = useState([]);
  const [filtered, setFiltered] = useState(false);
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [modalTitle, setModalTitle] = useState("");
  const [tableHeaders, setTableHeaders] = useState([
    "student_name",
    "Class",
    "gender",
    "parents_name",
    "parents_phone_number",
    "school_name",
    "district",
    "block",
    "cluster",
  ]);
  const [isShow, setIsShow] = useState(false);

  console.log("tableData", tableData);
  console.log("districtArr", districtArr);
  const districtname = localStorage.getItem("districtname");

  //?---------------Month array---------------------------
  const monthArr = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  //?-----------------Week array--------------------
  const weekArr = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
  ];

  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: 2 }, (_, index) => currentYear - index);

  const [selectedYear, setSelectedYear] = useState(currentYear);
  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const xlData = tableData;
  const fileName = "SchoolwiseReport.csv";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Api.get("getAllDistricts");
        if (
          response &&
          response?.data &&
          response?.data?.length > 0 &&
          response?.data
        ) {
          console.log("set=================>", response?.data);
          const districts =
            response?.data.length > 0 &&
            response?.data
              .filter(
                (item) =>
                  item?.district.toLowerCase() === districtname.toLowerCase()
              )
              .map((item) => item?.district);
        } else {
          setDistrictArr([]);
        }
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching districts:", error);
        // setLoading(false);
      }
    };

    fetchData();

    return () => {};
  }, []);

  // const handleYearChange = (e) => {
  //   setYear(e.target.value);
  // };

  const handleDistrictChange = (e) => {
    setDistricts(e.target.value);
    setBlocks("");
    setCluseters("");
    setSchools("");
    // Other logic related to district change
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Api.get(`getAllBlocksByDistrict/${districts}`);
        console.log("set=================>", response.data);
        if (response?.data && response?.data?.length > 0) {
          // Extracting the blocks from the response data
          const blocks =
            response?.data.length > 0 &&
            response?.data?.map((item) => item?.block);
          console.log("Blocks:", blocks);
          setBlockArr(blocks); // Setting the block array with the array of block names
        } else {
          console.log("No blocks found for the given district.");
          setBlockArr([]); // Setting an empty array if no data is found
        }
        // setLoading(false);
      } catch (error) {
        console.error("Error fetching Blocks:", error);
        // setLoading(false);
      }
    };

    // Fetch data only if a district is selected
    if (districts) {
      fetchData();
    }

    return () => {};
  }, [districts]);

  const currentMonth = moment().format("MMMM");
  const currentMonthSelected = monthArr?.filter(
    (item) => item.label === currentMonth
  )[0];

  const handleMonthChange = (e) => {
    if (
      e.target.value > currentMonthSelected.value &&
      selectedYear === currentYear
    ) {
      alert("You can't select a month greater than the current month !");
    } else {
      setSelectedWeek(1);
      setSelectedMonth(e.target.value ? e.target.value : "");
    }
  };

  const handleBlockChange = (e) => {
    setBlocks(e.target.value);
    setCluseters("");
    setSchools("");
    const data = filterData;
    console.log("data----->", data);

    if (e.target.value?.length > 0) {
      const res = data.filter((item) => item.block === e.target.value);

      const cluster = res?.map((item) => item.cluster);
      const uniqueData = Array.from(new Set(cluster.map(JSON.stringify))).map(
        JSON.parse
      );
      // console.log("uniqueData--->", res);
      setClusterArr(uniqueData);
    }
  };

  // useEffect(() => {
  //   const fetchClusters = async () => {
  //     try {
  //       if (blocks) {
  //         // setLoading(true);
  //         const response = await Api.get(`getAllClustersByBlock/${blocks}`);
  //         // console.log("set=================>", response.data);
  //         const clusters =
  //           response?.data?.length > 0 &&
  //           response?.data?.map((item) => item?.cluster);
  //         setClusterArr(clusters);
  //         // setLoading(false);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching Clusters:", error);
  //       setClusterArr([]); // Reset clusterArr to an empty array if there's an error
  //       // setLoading(false);
  //     }
  //   };

  //   // Fetch data only if a block is selected
  //   fetchClusters();
  // }, [blocks]);

  const handleClusterChange = (e) => {
    setCluseters(e.target.value);

    setSchools("");
    const data = filterData;
    const res = data.filter((item) => item.block === blocks);

    const cluster = res?.filter((item) => item.cluster === e.target.value);

    const school = cluster?.map((item) => item.school_name);
    const uniqueData = Array.from(new Set(school.map(JSON.stringify))).map(
      JSON.parse
    );
    setSchoolArr(uniqueData);
    console.log("uniqueData--->", school);
    console.log("uniqueData1--->", uniqueData);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       if (clusters) {
  //         const response = await Api.get(`getAllSchoolsByCluster/${clusters}`);
  //         setSchoolArr(response?.data);
  //         // setLoading(false);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching Blocks:", error);
  //       // setLoading(false);
  //     }
  //   };

  //   // Fetch data only if a district is selected
  //   fetchData();
  // }, [clusters]);

  const handleSchoolName = (e) => {
    setSchools(e.target.value);
  };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const handleWeekChange = (e) => {
    if (!selectedMonth && e.target.value) {
      alert("Please select a month before selecting a week !");
    } else {
      setSelectedWeek(e.target.value ? parseInt(e.target.value) : "");
    }
  };

  const [zeroToOneCountSum, setZeroToOneCountSum] = useState("");

  const [twoToFiveCountSum, setTwoToFiveCountSum] = useState("");
  const [sixteenToThirtyCountSum, setSixteenToThirtyCountSum] = useState("");
  const [thirtyOneToThirtyFiveCountSum, setThirtyOneToThirtyFiveCountSum] =
    useState("");
  const [fourtyFiveCountSum, setFourtyFiveCountSum] = useState("");
  const [zeroToOneCountAvg, setZeroToOneCountAvg] = useState("");

  const [twoToFiveCountAvg, setTwoToFiveCountAvg] = useState("");
  const [sixteenToThirtyCountAvg, setSixteenToThirtyCountAvg] = useState("");
  const [thirtyOneToThirtyFiveCountAvg, setThirtyOneToThirtyFiveCountAvg] =
    useState("");
  const [fourtyFiveCountAvg, setFourtyFiveCountAvg] = useState("");

  const fetchData = () => {
    const filteredData = dataJson.filter((item) => {
      return (
        item.month === selectedMonth &&
        item.week === selectedWeek &&
        item.block === blocks &&
        item.cluster === clusters &&
        item.school_name === schools
        // &&
        // item.block == blocks &&
        // item.cluster == clusters &&
        // item.school_name == schools?.school_name
      );
    });

    console.log("filteredData---->", filteredData);
    const zeroToOneCount = filteredData.reduce(
      (acc, item) => acc + item.no_of_parents_spent_0to1mins,
      0
    );

    const twoToFiveCount = filteredData.reduce(
      (acc, item) => acc + item.no_of_parents_spent_2to5mins,
      0
    );
    const sixteenToThirtyCount = filteredData.reduce(
      (acc, item) => acc + item.no_of_parents_spent_16to30mins,

      0
    );
    const thirtyOneToFourtyFiveCount = filteredData.reduce(
      (acc, item) => acc + item.no_of_parents_spent_31to45mins,

      0
    );
    const FourtyFiveCount = filteredData.reduce(
      (acc, item) => acc + item.no_of_parents_spent_gte45mins,

      0
    );

    const zeroToOneAvg = filteredData.reduce(
      (acc, item) => acc + item.avg_tS_0to1mins,
      0
    );

    const twoToFiveAvg = filteredData.reduce(
      (acc, item) => acc + item.avg_tS_2to5mins,
      0
    );
    const sixteenToThirtyAvg = filteredData.reduce(
      (acc, item) => acc + item.avg_tS_16to30mins,

      0
    );
    const thirtyOneToFourtyFiveAvg = filteredData.reduce(
      (acc, item) => acc + item.avg_tS_31to45mins,

      0
    );
    const FourtyFiveAvg = filteredData.reduce(
      (acc, item) => acc + item.avg_tS_gte45mins,

      0
    );

    setZeroToOneCountAvg(zeroToOneAvg);
    setTwoToFiveCountAvg(twoToFiveAvg);
    setSixteenToThirtyCountAvg(sixteenToThirtyAvg);
    setThirtyOneToThirtyFiveCountAvg(thirtyOneToFourtyFiveAvg);

    setFourtyFiveCountAvg(FourtyFiveAvg);

    setZeroToOneCountSum(zeroToOneCount);
    setFourtyFiveCountSum(FourtyFiveCount);
    setSixteenToThirtyCountSum(sixteenToThirtyCount);
    setThirtyOneToThirtyFiveCountSum(thirtyOneToFourtyFiveCount);
    setTwoToFiveCountSum(twoToFiveCount);
    if (filteredData?.length > 0) {
      setData(filteredData);
      setLoading(false);
    } else {
      setLoading(false);
      setData([]);
    }
    // 1 4 KADUA PS {school_name: 'AHAMADPUR PUPS'} SATYABADI

    console.log(
      "check---------->",
      selectedMonth,
      selectedWeek,
      clusters,
      schools,
      blocks
    );
  };

  console.log("zeroToOneCountSum--->", zeroToOneCountSum);
  const filterButtonClick = () => {
    setFiltered(true);
    if (!districts) {
      alert("Please select a district to proceed.");
    } else if (!selectedMonth) {
      alert("Please select a Month to proceed.");
    } else if (!selectedWeek) {
      alert("Please select a week to proceed.");
    } else if (districts && blocks && !clusters) {
      alert("Please select a cluster to proceed.");
    } else if (districts && blocks && clusters && !schools) {
      alert("Please select a school to proceed.");
    } else if (!blocks && !clusters && !schools) {
      alert("Please select block, cluster and school to proceed.");
    } else {
      setLoading(true);
      setIsShow(true);
      fetchData();
    }
  };

  const handleOpenModal = async (type, param) => {
    setOpen(true);
    setLoading(true);
    setTableData([]); // Reset table data before fetching new data
    setTableHeaders([]);
    setModalTitle("");
    let body = {
      ...(districts && { district: districts }),
      ...(blocks && { block: blocks }),
      ...(clusters && { cluster: clusters }),
      ...(schools && { school_name: schools?.school_name }),
    };

    if (type === "chatbotConvo") {
      setModalTitle(param);
      try {
        const response = await Api.post("/getChatBotConvosReport", body);
        const transformedData = response.data?.map((student) => ({
          student_name: student.student_name,
          class: student.class,
          gender: student.gender,
          parents_name: student.parents_name,
          school_name: student.school_name,
          district: student.district,
          block: student.block,
          cluster: student.cluster,
          phone_number: student.phone_number || "-",
          buttonClicked: student.buttonClicked || "-",
          templateName: student.templateName || "-",
          msgType: student.msgType || "-",
          status: student.status,
          createdAt: moment(student.createdAt).format("DD-MM-YYYY hh:mm"),
        }));
        setTableData(transformedData);
        setTableHeaders([
          "Student Name",
          "Class",
          "Gender",
          "Parents Name",
          "School Name",
          "District",
          "Block",
          "Cluster",
          "Phone Number",
          "Button Clicked",
          "Template Name",
          "Msg Type",
          "Status",
          "Created on",
        ]);
      } catch (error) {
        console.error("Error fetching chatbot conversations:", error);
      }
    } else if (type === "studentReport") {
      const classNumber = param;
      classNumber
        ? setModalTitle(`Number Of Students in class ${classNumber}`)
        : setModalTitle("Number Of Students");
      body = {
        ...body,
        ...(classNumber && { class: classNumber }),
      };
      try {
        const response = await Api.post("/getAllStudentsReport", body);
        setTableData(response.data);
        setTableHeaders([
          "student_name",
          "Class",
          "gender",
          "parents_name",
          "parents_phone_number",
          "school_name",
          "district",
          "block",
          "cluster",
        ]);
      } catch (error) {
        console.error("Error fetching student report:", error);
      }
    }

    // setModalTitle(newModalTitle);
    setLoading(false);
  };

  const handleClose = () => setOpen(false);

  return (
    <div>
      <div
        style={{
          display: "flex",
          marginTop: "4%",
          marginLeft: "53%",
          flexWrap: "wrap",
        }}
      >
        <FormControl sx={{ m: 1 }} size="small" style={{ width: "120px" }}>
          <InputLabel id="district-label">District</InputLabel>
          <Select
            labelId="district-label"
            id="district-select"
            value={districts}
            onChange={(e) => handleDistrictChange(e)}
            label="District"
          >
            {districtArr?.map((district, index) => (
              <MenuItem key={index} value={district}>
                {district}
              </MenuItem>
            ))}
            <MenuItem value="PURI">PURI</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1 }} size="small" style={{ width: "120px" }}>
          <InputLabel id="block-label">Block</InputLabel>
          <Select
            labelId="block-label"
            id="block-select"
            value={blocks}
            onChange={handleBlockChange}
            label="Block"
            disabled={loading || !districts}
          >
            {/* <MenuItem value="">None</MenuItem> */}
            {blockArr &&
              blockArr?.map((block, index) => (
                <MenuItem key={index} value={block}>
                  {block}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1 }} size="small" style={{ width: "120px" }}>
          <InputLabel id="cluster-label">Cluster</InputLabel>
          <Select
            labelId="cluster-label"
            id="cluster-select"
            value={clusters}
            onChange={handleClusterChange}
            label="Cluster"
            disabled={loading || !blocks}
          >
            {/* <MenuItem value="">None</MenuItem> */}
            {clusterArr?.map((cluster, index) => (
              <MenuItem key={index} value={cluster}>
                {cluster}
              </MenuItem>
            ))}
          </Select>
          {/* {loading && <CircularProgress size={24} />} */}
        </FormControl>

        <FormControl sx={{ m: 1 }} size="small" style={{ width: "120px" }}>
          <InputLabel id="school-label">School</InputLabel>
          <Select
            labelId="school-label"
            id="school-select"
            value={schools}
            onChange={handleSchoolName}
            label="School"
            disabled={!clusters}
          >
            {/* <MenuItem value="">None</MenuItem> */}
            {schoolArr?.map((school, index) => (
              <MenuItem key={index} value={school}>
                {school}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1 }} size="small" style={{ width: "120px" }}>
          <InputLabel id="usertype-label">Month</InputLabel>
          <Select
            labelId="usertype-label"
            id="usertype-select"
            value={selectedMonth}
            onChange={handleMonthChange}
            label="Month"
          >
            {/* <MenuItem value={null}>None</MenuItem> */}
            {monthArr.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ m: 1 }} size="small" style={{ width: "120px" }}>
          <InputLabel id="usertype-label">Week</InputLabel>
          <Select
            labelId="usertype-label"
            id="usertype-select"
            value={selectedWeek}
            onChange={handleWeekChange}
            label="Month"
          >
            {/* <MenuItem value={null}>None</MenuItem> */}
            {weekArr.map((item, index) => (
              <MenuItem key={index} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        > */}
        <Button
          variant="contained"
          sx={{
            height: "40px",
            width: "120px",
            marginTop: "1.2%",
            // marginRight: "5.9%",
            marginLeft: "9px",
          }}
          onClick={filterButtonClick}
        >
          Filter
        </Button>
        {/* </Box> */}
      </div>
      {/* ---------------------------- content --------------------- */}
      {loading ? (
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: "5%" }}
        >
          <Box>
            <CircularProgress />
          </Box>
        </div>
      ) : !loading && data?.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            width: "96%",
            marginLeft: "3%",
            marginBottom: "2%",
            marginTop: "2%",
          }}
        >
          <div
            style={{
              marginTop: "2%",
              boxShadow: "2px 1px 5px grey",
              padding: "3%",
              width: "97%",
            }}
          >
            <div style={{ marginTop: "-2%" }}>
              <h1
                style={{
                  marginTop: "-2%",
                  color: "#333", // Dark grey color for the text
                  fontFamily: "Congenial SemiBold", // Font family for a clean look
                  fontWeight: "700", // Bolder font weight for emphasis
                  fontSize: "1.8rem", // Larger font size for prominence
                  textAlign: "center", // Center-align the text
                  padding: "10px 0", // Add some padding for spacing
                  borderBottom: "2px solid #000000", // Add a bottom border for separation
                  letterSpacing: "0.5px", // Slight letter spacing for readability
                  textTransform: "capitalize", // Capitalize each word
                }}
              >
                School-wise Details
              </h1>
            </div>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignContent: "center",
                justifyContent: "center",
                width: "97%",
                gap: "2%",
              }}
            >
              <>
                <div
                  style={{
                    width: "255px",
                    height: "220px", // Increased height to accommodate heading
                    marginTop: "1.5%",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "1px 1px 4px 3px lightGrey",
                  }}
                >
                  {/* Heading */}
                  <div
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      color: "#00CED1",
                      fontSize: "1.3rem", // Heading font size
                      fontWeight: "bold",
                      // fontSize: "1rem", // Reduced font size
                      fontFamily: "Congenial SemiBold",
                    }}
                  >
                    Time Spent 0-1 mins
                  </div>

                  {/* User and Avg. Time Spent Section */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      height: "50%",
                      color: "#00CED1",
                      fontSize: "1rem", // Reduced font size
                      fontFamily: "Congenial SemiBold",
                      fontWeight: "600",
                    }}
                  >
                    <div
                      style={{
                        width: "45%",
                        textAlign: "center",
                      }}
                    >
                      Users
                    </div>
                    {/* Line divider */}
                    <div
                      style={{
                        height: "100%",
                        borderLeft: "1px solid #00CED1", // Line between elements
                        margin: "0 5px",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "45%",
                        textAlign: "center",
                      }}
                    >
                      Avg. Time (in mins)
                    </div>
                  </div>

                  {/* User count and Avg. Time Spent Values */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      backgroundColor: "#00CED1",
                      borderEndStartRadius: "10px",
                      borderEndEndRadius: "10px",
                      color: "white",
                      padding: "10px",
                      height: "50%",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        fontSize: "1rem", // Reduced font size
                      }}
                    >
                      <h2>{zeroToOneCountSum}</h2>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        fontSize: "0.7rem", // Reduced font size
                      }}
                    >
                      <h1>{zeroToOneCountAvg}</h1>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "255px",
                    height: "220px", // Increased height to accommodate heading
                    marginTop: "1.5%",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "1px 1px 4px 3px lightGrey",
                  }}
                >
                  {/* Heading */}
                  <div
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      color: "#CD5C5C",
                      fontSize: "1.3rem", // Heading font size
                      fontWeight: "bold",
                      // fontSize: "1rem", // Reduced font size
                      fontFamily: "Congenial SemiBold",
                    }}
                  >
                    Time Spent 2-15 mins
                  </div>

                  {/* User and Avg. Time Spent Section */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      height: "50%",
                      color: "#CD5C5C",
                      fontSize: "1rem", // Reduced font size
                      fontFamily: "Congenial SemiBold",
                      fontWeight: "600",
                    }}
                  >
                    <div
                      style={{
                        width: "45%",
                        textAlign: "center",
                      }}
                    >
                      Users
                    </div>
                    {/* Line divider */}
                    <div
                      style={{
                        height: "100%",
                        borderLeft: "1px solid #CD5C5C", // Line between elements
                        margin: "0 5px",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "45%",
                        textAlign: "center",
                      }}
                    >
                      Avg. Time (in mins)
                    </div>
                  </div>

                  {/* User count and Avg. Time Spent Values */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      backgroundColor: "#CD5C5C",
                      borderEndStartRadius: "10px",
                      borderEndEndRadius: "10px",
                      color: "white",
                      padding: "10px",
                      height: "50%",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        fontSize: "1rem", // Reduced font size
                      }}
                    >
                      <h2>{twoToFiveCountSum}</h2>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        fontSize: "0.7rem", // Reduced font size
                      }}
                    >
                      <h1>{twoToFiveCountAvg}</h1>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "255px",
                    height: "220px", // Increased height to accommodate heading
                    marginTop: "1.5%",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "1px 1px 4px 3px lightGrey",
                  }}
                >
                  {/* Heading */}
                  <div
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      color: "#CD5C5C",
                      fontSize: "1.3rem", // Heading font size
                      fontWeight: "bold",
                      // fontSize: "1rem", // Reduced font size
                      fontFamily: "Congenial SemiBold",
                    }}
                  >
                    Time Spent 16-30 mins
                  </div>

                  {/* User and Avg. Time Spent Section */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      height: "50%",
                      color: "#CD5C5C",
                      fontSize: "1rem", // Reduced font size
                      fontFamily: "Congenial SemiBold",
                      fontWeight: "600",
                    }}
                  >
                    <div
                      style={{
                        width: "45%",
                        textAlign: "center",
                      }}
                    >
                      Users
                    </div>
                    {/* Line divider */}
                    <div
                      style={{
                        height: "100%",
                        borderLeft: "1px solid #CD5C5C", // Line between elements
                        margin: "0 5px",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "45%",
                        textAlign: "center",
                      }}
                    >
                      Avg. Time (in mins)
                    </div>
                  </div>

                  {/* User count and Avg. Time Spent Values */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      backgroundColor: "#CD5C5C",
                      borderEndStartRadius: "10px",
                      borderEndEndRadius: "10px",
                      color: "white",
                      padding: "10px",
                      height: "50%",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        fontSize: "1rem", // Reduced font size
                      }}
                    >
                      <h2>{sixteenToThirtyCountSum}</h2>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        fontSize: "0.7rem", // Reduced font size
                      }}
                    >
                      <h1>{sixteenToThirtyCountAvg}</h1>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "255px",
                    height: "220px", // Increased height to accommodate heading
                    marginTop: "1.5%",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "1px 1px 4px 3px lightGrey",
                  }}
                >
                  {/* Heading */}
                  <div
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      color: "#2E8B57",
                      fontSize: "1.3rem", // Heading font size
                      fontWeight: "bold",
                      // fontSize: "1rem", // Reduced font size
                      fontFamily: "Congenial SemiBold",
                    }}
                  >
                    Time Spent 31-45 mins
                  </div>

                  {/* User and Avg. Time Spent Section */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      height: "50%",
                      color: "#2E8B57",
                      fontSize: "1rem", // Reduced font size
                      fontFamily: "Congenial SemiBold",
                      fontWeight: "600",
                    }}
                  >
                    <div
                      style={{
                        width: "45%",
                        textAlign: "center",
                      }}
                    >
                      Users
                    </div>
                    {/* Line divider */}
                    <div
                      style={{
                        height: "100%",
                        borderLeft: "1px solid #2E8B57", // Line between elements
                        margin: "0 5px",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "45%",
                        textAlign: "center",
                      }}
                    >
                      Avg. Time (in mins)
                    </div>
                  </div>

                  {/* User count and Avg. Time Spent Values */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      backgroundColor: "#2E8B57",
                      borderEndStartRadius: "10px",
                      borderEndEndRadius: "10px",
                      color: "white",
                      padding: "10px",
                      height: "50%",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        fontSize: "1rem", // Reduced font size
                      }}
                    >
                      <h2>{thirtyOneToThirtyFiveCountSum}</h2>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        fontSize: "0.7rem", // Reduced font size
                      }}
                    >
                      <h1>{thirtyOneToThirtyFiveCountAvg}</h1>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    width: "255px",
                    height: "220px", // Increased height to accommodate heading
                    marginTop: "1.5%",
                    backgroundColor: "white",
                    borderRadius: "10px",
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "1px 1px 4px 3px lightGrey",
                  }}
                >
                  {/* Heading */}
                  <div
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      color: "#6A5ACD",
                      fontSize: "1.3rem", // Heading font size
                      fontWeight: "bold",
                      // fontSize: "1rem", // Reduced font size
                      fontFamily: "Congenial SemiBold",
                    }}
                  >
                    Time Spent 45+ mins
                  </div>

                  {/* User and Avg. Time Spent Section */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px",
                      height: "50%",
                      color: "#6A5ACD",
                      fontSize: "1rem", // Reduced font size
                      fontFamily: "Congenial SemiBold",
                      fontWeight: "600",
                    }}
                  >
                    <div
                      style={{
                        width: "45%",
                        textAlign: "center",
                      }}
                    >
                      Users
                    </div>
                    {/* Line divider */}
                    <div
                      style={{
                        height: "100%",
                        borderLeft: "1px solid #6A5ACD", // Line between elements
                        margin: "0 5px",
                      }}
                    ></div>
                    <div
                      style={{
                        width: "45%",
                        textAlign: "center",
                      }}
                    >
                      Avg. Time (in mins)
                    </div>
                  </div>

                  {/* User count and Avg. Time Spent Values */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      textAlign: "center",
                      alignItems: "center",
                      backgroundColor: "#6A5ACD",
                      borderEndStartRadius: "10px",
                      borderEndEndRadius: "10px",
                      color: "white",
                      padding: "10px",
                      height: "50%",
                    }}
                  >
                    <div
                      style={{
                        width: "50%",
                        fontSize: "1rem", // Reduced font size
                      }}
                    >
                      <h2>{fourtyFiveCountSum}</h2>
                    </div>
                    <div
                      style={{
                        width: "50%",
                        fontSize: "0.7rem", // Reduced font size
                      }}
                    >
                      <h1>{fourtyFiveCountAvg}</h1>
                    </div>
                  </div>
                </div>
              </>

              {/* <div
                onClick={() => handleOpenModal("studentReport")}
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                  cursor: "pointer", // Show hand cursor on hover
                  position: "relative", // Needed for positioning the "Click here" text
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0px", // Adjust to position the text at the top
                    right: "0px", // Adjust to position the text at the right
                    color: "#CD5C5C", // Text color
                    backgroundColor: "white", // Background color to make it stand out
                    padding: "5px 10px", // Padding to add some space inside the border
                    fontSize: "0.7rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                    borderRadius: "5px", // Rounded corners for a smoother look
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for a 3D effect
                    zIndex: "10", // Ensure it stays on top of other elements
                  }}
                >
                  Click Here 👆
                </div>
                <div
                  style={{
                    height: "50%",
                    color: "#CD5C5C",
                    paddingTop: "13px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  <p> Number of students</p>
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "#CD5C5C",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.total_students}</h1>
                </div>
              </div> */}
              {/* <div
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  // // paddingTop: "2%",
                  // fontFamily: "Arial, sans-serif", // Default font family
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                }}
              >
                <div
                  style={{
                    height: "50%",
                    color: "rgb(214 148 16)",
                    paddingTop: "18px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  Total No. of Parents PoC Identified
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "rgb(214 148 16)",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.total_poc}</h1>
                </div>
              </div> */}
              {/* <div
                onClick={() => handleOpenModal("studentReport", 1)}
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                  cursor: "pointer", // Show hand cursor on hover
                  position: "relative", // Needed for positioning the "Click here" text
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0px", // Adjust to position the text at the top
                    right: "0px", // Adjust to position the text at the right
                    color: "rgb(153 58 134)", // Text color
                    backgroundColor: "white", // Background color to make it stand out
                    padding: "5px 10px", // Padding to add some space inside the border
                    fontSize: "0.7rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                    borderRadius: "5px", // Rounded corners for a smoother look
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for a 3D effect
                    zIndex: "10", // Ensure it stays on top of other elements
                  }}
                >
                  Click Here 👆
                </div>
                <div
                  style={{
                    height: "50%",
                    color: "rgb(153 58 134)",
                    paddingTop: "28px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  Number of Students in Class 1
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "rgb(153 58 134)",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.class_one_students}</h1>
                </div>
              </div>
              <div
                onClick={() => handleOpenModal("studentReport", 2)}
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                  cursor: "pointer", // Show hand cursor on hover
                  position: "relative", // Needed for positioning the "Click here" text
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0px", // Adjust to position the text at the top
                    right: "0px", // Adjust to position the text at the right
                    color: "#2E8B57", // Text color
                    backgroundColor: "white", // Background color to make it stand out
                    padding: "5px 10px", // Padding to add some space inside the border
                    fontSize: "0.7rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                    borderRadius: "5px", // Rounded corners for a smoother look
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for a 3D effect
                    zIndex: "10", // Ensure it stays on top of other elements
                  }}
                >
                  Click Here 👆
                </div>
                <div
                  style={{
                    height: "50%",
                    color: "#2E8B57",
                    paddingTop: "28px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  Number of Students in Class 2
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "#2E8B57",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.class_two_students}</h1>
                </div>
              </div>

              <div
                onClick={() => handleOpenModal("studentReport", 3)}
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                  cursor: "pointer", // Show hand cursor on hover
                  position: "relative", // Needed for positioning the "Click here" text
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0px", // Adjust to position the text at the top
                    right: "0px", // Adjust to position the text at the right
                    color: "rgb(214 148 16)", // Text color
                    backgroundColor: "white", // Background color to make it stand out
                    padding: "5px 10px", // Padding to add some space inside the border
                    fontSize: "0.7rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                    borderRadius: "5px", // Rounded corners for a smoother look
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for a 3D effect
                    zIndex: "10", // Ensure it stays on top of other elements
                  }}
                >
                  Click Here 👆
                </div>
                <div
                  style={{
                    height: "50%",
                    color: "rgb(214 148 16)",
                    paddingTop: "13px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  <p> Number of Students in Class 3</p>
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "rgb(214 148 16)",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.class_three_students}</h1>
                </div>
              </div>
              <div
                onClick={() => handleOpenModal("studentReport", 4)}
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                  cursor: "pointer", // Show hand cursor on hover
                  position: "relative", // Needed for positioning the "Click here" text
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0px", // Adjust to position the text at the top
                    right: "0px", // Adjust to position the text at the right
                    color: "#6A5ACD", // Text color
                    backgroundColor: "white", // Background color to make it stand out
                    padding: "5px 10px", // Padding to add some space inside the border
                    fontSize: "0.7rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                    borderRadius: "5px", // Rounded corners for a smoother look
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for a 3D effect
                    zIndex: "10", // Ensure it stays on top of other elements
                  }}
                >
                  Click Here 👆
                </div>
                <div
                  style={{
                    height: "50%",
                    color: "#6A5ACD",
                    paddingTop: "28px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  Number of Students in Class 4
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "#6A5ACD",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.class_four_students}</h1>
                </div>
              </div>
              <div
                onClick={() => handleOpenModal("studentReport", 5)}
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                  cursor: "pointer", // Show hand cursor on hover
                  position: "relative", // Needed for positioning the "Click here" text
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0px", // Adjust to position the text at the top
                    right: "0px", // Adjust to position the text at the right
                    color: "#2E8B57", // Text color
                    backgroundColor: "white", // Background color to make it stand out
                    padding: "5px 10px", // Padding to add some space inside the border
                    fontSize: "0.7rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                    borderRadius: "5px", // Rounded corners for a smoother look
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for a 3D effect
                    zIndex: "10", // Ensure it stays on top of other elements
                  }}
                >
                  Click Here 👆
                </div>
                <div
                  style={{
                    height: "50%",
                    color: "#2E8B57",
                    paddingTop: "28px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  Number of Students in Class 5
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "#2E8B57",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.class_five_students}</h1>
                </div>
              </div>

              <div
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  // // paddingTop: "2%",
                  // fontFamily: "Arial, sans-serif", // Default font family
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                }}
              >
                <div
                  style={{
                    height: "50%",
                    color: "rgb(214 148 16)",
                    paddingTop: "13px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  <p> Total Time Spent</p>
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "rgb(214 148 16)",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.total_timespent}</h1>
                </div>
              </div> */}

              {/* <div
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  // // paddingTop: "2%",
                  // fontFamily: "Arial, sans-serif", // Default font family
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                }}
              >
                <div
                  style={{
                    height: "50%",
                    color: "rgb(153 58 134)",
                    paddingTop: "20px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  Number of Parents Spent 16-30 mins
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "rgb(153 58 134)",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.no_of_parents_spent_16to30mins}</h1>
                </div>
              </div> */}
            </div>
          </div>

          {/* <div
            style={{
              marginTop: "2%",
              boxShadow: "2px 1px 5px grey",
              padding: "3%",
              width: "97%",
            }}
          >
            <h1
              style={{
                marginTop: "-2%",
                color: "#333", // Dark grey color for the text
                fontFamily: "Congenial SemiBold", // Font family for a clean look
                fontWeight: "700", // Bolder font weight for emphasis
                fontSize: "1.8rem", // Larger font size for prominence
                textAlign: "center", // Center-align the text
                padding: "10px 0", // Add some padding for spacing
                borderBottom: "2px solid #000000", // Add a bottom border for separation
                letterSpacing: "0.5px", // Slight letter spacing for readability
                textTransform: "capitalize", // Capitalize each word
              }}
            >
              Remote Instructions in Brief
            </h1>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignContent: "center",
                justifyContent: "center",
                width: "97%",
                gap: "2%",
              }}
            >
              <div
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  // // paddingTop: "2%",
                  // fontFamily: "Arial, sans-serif", // Default font family
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                }}
              >
                <div
                  style={{
                    height: "50%",
                    color: "#2E8B57",
                    paddingTop: "13px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  <p> Total No. of Calls received</p>
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "#2E8B57",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.total_calls_received}</h1>
                </div>
              </div>
              <div
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  // // paddingTop: "2%",
                  // fontFamily: "Arial, sans-serif", // Default font family
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                }}
              >
                <div
                  style={{
                    height: "50%",
                    color: "rgb(153 58 134)",
                    paddingTop: "20px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  Average minutes of calls received
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "rgb(153 58 134)",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.calls_avg_mins}</h1>
                </div>
              </div>
              <div
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  // // paddingTop: "2%",
                  // fontFamily: "Arial, sans-serif", // Default font family
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                }}
              >
                <div
                  style={{
                    height: "50%",
                    color: "#CD5C5C",
                    paddingTop: "20px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  Total No. of SMS delivered
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "#CD5C5C",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.total_sms_received}</h1>
                </div>
              </div>
              <div
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  // // paddingTop: "2%",
                  // fontFamily: "Arial, sans-serif", // Default font family
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                }}
              >
                <div
                  style={{
                    height: "50%",
                    color: "rgb(214 148 16)",
                    paddingTop: "20px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  Average minutes spent in IVRS
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "rgb(214 148 16)",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.ivrs_avg_mins}</h1>
                </div>
              </div>
              <div
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  // // paddingTop: "2%",
                  // fontFamily: "Arial, sans-serif", // Default font family
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                }}
              >
                <div
                  style={{
                    height: "50%",
                    color: "#CD5C5C",
                    paddingTop: "20px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  Total No. of calls received in IVRs
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "#CD5C5C",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.total_ivrs_calls_received}</h1>
                </div>
              </div>

              <div
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  // // paddingTop: "2%",
                  // fontFamily: "Arial, sans-serif", // Default font family
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                }}
              >
                <div
                  style={{
                    height: "50%",
                    color: "rgb(214 148 16)",
                    paddingTop: "20px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  Unique Calls Received in IVR
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "rgb(214 148 16)",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.total_unique_ivrs_calls_received}</h1>
                </div>
              </div>

              <div
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  // // paddingTop: "2%",
                  // fontFamily: "Arial, sans-serif", // Default font family
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                }}
              >
                <div
                  style={{
                    height: "50%",
                    color: "#2E8B57",
                    paddingTop: "13px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  <p> Number of Active Users</p>
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "#2E8B57",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.calls_active_users}</h1>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div
            style={{
              marginTop: "2%",
              boxShadow: "2px 1px 5px grey",
              padding: "3%",
              width: "97%",
            }}
          >
            <h1
              style={{
                marginTop: "-2%",
                color: "#333", // Dark grey color for the text
                fontFamily: "Congenial SemiBold", // Font family for a clean look
                fontWeight: "700", // Bolder font weight for emphasis
                fontSize: "1.8rem", // Larger font size for prominence
                textAlign: "center", // Center-align the text
                padding: "10px 0", // Add some padding for spacing
                borderBottom: "2px solid #000000", // Add a bottom border for separation
                letterSpacing: "0.5px", // Slight letter spacing for readability
                textTransform: "capitalize", // Capitalize each word
              }}
            >
              Chatbot in Brief
            </h1>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignContent: "center",
                justifyContent: "center",
                width: "100%",
                gap: "2%",
              }}
            >
              <div
                onClick={() => {
                  handleOpenModal(
                    "chatbotConvo",
                    "Total Conversations in Chatbot"
                  );
                }}
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                  cursor: "pointer", // Show hand cursor on hover
                  position: "relative", // Needed for positioning the "Click here" text
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    top: "0px", // Adjust to position the text at the top
                    right: "0px", // Adjust to position the text at the right
                    color: "#6A5ACD", // Text color
                    backgroundColor: "white", // Background color to make it stand out
                    padding: "5px 10px", // Padding to add some space inside the border
                    fontSize: "0.7rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                    borderRadius: "5px", // Rounded corners for a smoother look
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Subtle shadow for a 3D effect
                    zIndex: "10", // Ensure it stays on top of other elements
                  }}
                >
                  Click Here 👆
                </div>
                <div
                  style={{
                    height: "50%",
                    color: "#6A5ACD",
                    paddingTop: "26px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  Total Conversations in Chatbot
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "#6A5ACD",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.total_chatbot_convo}</h1>
                </div>
              </div>

              <div
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
       
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                }}
              >
                <div
                  style={{
                    height: "50%",
                    color: "rgb(153 58 134)",
                    paddingTop: "20px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  Total No. of Videos Watched
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "rgb(153 58 134)",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.total_chatbot_videos}</h1>
                </div>
              </div>

              <div
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  // // paddingTop: "2%",
                  // fontFamily: "Arial, sans-serif", // Default font family
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                }}
              >
                <div
                  style={{
                    height: "50%",
                    color: "#2E8B57",
                    paddingTop: "20px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  Total No. of Assessment Taken
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "#2E8B57",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.total_chatbot_assess_taken}</h1>
                </div>
              </div> 

             <div
                onClick={() => handleOpen()}
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  // // paddingTop: "2%",
                  // fontFamily: "Arial, sans-serif", // Default font family
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                }}
              >
                <div
                  style={{
                    height: "50%",
                    color: "#6A5ACD",
                    paddingTop: "20px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  Average minutes spent in WhatsApp
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "#6A5ACD",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.chatbot_avg_mins}</h1>
                </div>
              </div> 

              <div
                // onClick={() => handleOpen()}
                style={{
                  width: "255px",
                  height: "180px",
                  marginTop: "1.5%",
                  backgroundColor: "white",
                  // // paddingTop: "2%",
                  // fontFamily: "Arial, sans-serif", // Default font family
                  borderRadius: "10px",
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: "1px 1px 4px 3px lightGrey",
                }}
              >
                <div
                  style={{
                    height: "50%",
                    color: "rgb(214 148 16)",
                    paddingTop: "13px",
                    fontSize: "1.2rem",
                    fontFamily: "Congenial SemiBold",
                    fontWeight: "600",
                  }}
                >
                  <p> Number of Active Users</p>
                </div>
                <div
                  style={{
                    height: "50%",
                    backgroundColor: "rgb(214 148 16)",
                    borderEndStartRadius: "10px",
                    borderEndEndRadius: "10px",
                    color: "white",
                  }}
                >
                  <h1>{data.chatbot_active_users}</h1>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      ) : !loading && filtered && Object.keys(data).length === 0 ? (
        <img src={Nodata} />
      ) : (
        <img src={defaultImage} width={"20%"} />
      )}
      <div>
        <canvas ref={chartRef}></canvas>
      </div>
      {/* <DynamicModal
        open={open}
        loading={loading}
        handleClose={handleClose}
        modalTitle={modalTitle}
        tableHeaders={tableHeaders}
        tableData={tableData}
        xlData={xlData}
        fileName={fileName}
      /> */}
    </div>
  );
};

export default Schoolwise_performance;

const dataJson = [
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 30,
    avg_tS_gte45mins: 76.11,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 56,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 110,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 67.98,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 76.98,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 71,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 15,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 20,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 29,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 198,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 78.98,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 90,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 33.34,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 101,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 34,
    avg_tS_31to45mins: 32.67,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78.98,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.32,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39.12,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 145,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.98,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 104,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 93.45,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.7,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 115,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 105,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.39,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 0,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34.1,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 67.98,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 35,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34.23,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 87,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 59,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 94,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 101,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.98,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 65,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 37.98,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 86,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.32,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 104,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.9,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40.43,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 92.26,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 102.87,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 54.4,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 103,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 93.65,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37.28,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 88.98,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 76,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 73,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 11,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 46,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 89.45,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 89,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.23,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 27.8,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 42.9,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 66,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.67,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 67.14,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 71,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34.31,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 87,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.12,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 0,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.31,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 99,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 45,
    avg_tS_gte45mins: 101,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 107,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 67.98,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 11,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 110,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 18.9,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 104,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 103,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27.89,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 0,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23.19,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 88.23,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 74,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.4,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 87,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 49.98,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 80,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 117,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 44.32,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 120.23,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 0,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 25,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 35,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 104,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 92,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 63.67,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 39.99,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 111.11,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 25,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 0,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 20,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 107,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 103.67,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 38.78,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 89.34,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 107,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.54,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34.67,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 77,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32.72,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 97.45,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 65,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 56.34,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 98,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73.65,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 0,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 93.98,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 139.27,
  },
  {
    month: 1,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 0,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 19.4,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 87,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.12,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 73,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 6.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 48,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 33.34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 52,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.5,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.89,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42.21,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 47,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 52,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 69.62,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.75,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 66,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45.17,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 104,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.55,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 50,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33.98,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32.67,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 53,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 94.11,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 22.39,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.09,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 87.11,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65.12,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.32,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.46,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 50,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 53,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 32,
    avg_tS_16to30mins: 23.19,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 69.3,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 48,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 48,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.1,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 17,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 68,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.66,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 48,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 46,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 36.15,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 47,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 51,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 103,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49.7,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.02,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.08,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.02,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 94,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 46,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 48,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.02,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 95,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.32,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 46,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 30.56,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 67,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 46,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 27.56,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 83.65,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29.99,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 44.17,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.4,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 65,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.37,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.39,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 65,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 56,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.18,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69.65,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 5.7,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 85,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 68,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 24.6,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 56,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 58,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 24.3,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 56,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34.23,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 77,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.28,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 58,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 115,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29.7,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.28,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 68,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 105,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 58,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 18.88,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.77,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 107,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 98,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 65,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 13,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.32,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.63,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 16,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 20,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44.31,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25.3,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 50,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.89,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 86,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 16,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 69.3,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 87.23,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.89,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 46,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 103,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 66,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 55,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.9,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.31,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 58,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 108,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.98,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 105,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 0,
    avg_tS_2to5mins: 0,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41.65,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.14,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.9,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.53,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40.44,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.5,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 69.5,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 50,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 50,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 66,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.39,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 69.6,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 103,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 115,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 44.31,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 69.45,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 22,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27.43,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 105,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 59,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43.43,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 69.37,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 71.34,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.1,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 49.8,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 69.45,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.45,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 74,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 10.12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.31,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 66,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 104,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.83,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 77,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.7,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 68,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.49,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.89,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 58,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.7,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42.29,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31.61,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.62,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 52,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 86,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.68,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 65,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.34,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.79,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 87,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.47,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 90.56,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 68,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 48,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 50,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 56,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.98,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.31,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.29,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 32,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 45.48,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.21,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.45,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 66,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28.98,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 44.39,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 65,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 76,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 27.56,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 41.56,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69.53,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 18.3,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 98.23,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 67.87,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 68,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 27.6,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 27.8,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69.3,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 71,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 67,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26.8,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 88,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 22.4,
    no_of_parents_spent_31to45mins: 25,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 69.57,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.1,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 68,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.3,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 69.06,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 59,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69.2,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 19,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.47,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 69.46,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18.3,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.1,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.56,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43.61,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 65,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.98,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 56,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17.72,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 69.18,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 5.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 33.34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 19.32,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 32.34,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 55,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 15,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 44.73,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29.9,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.08,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 54,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 13,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 115,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 105,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 37.98,
    no_of_parents_spent_gte45mins: 45,
    avg_tS_gte45mins: 55,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 0,
    avg_tS_2to5mins: 0,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 32.4,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.23,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 108,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.67,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32.21,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 95,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 80,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29.5,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 108,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 56,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 69.49,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.11,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 19.3,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 46,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.4,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 55,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.45,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.4,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43.67,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 30.1,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 69.56,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 44.17,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 66.4,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 86,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.5,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40.65,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 115,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.8,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 26.89,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 112,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 75,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32.34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 50,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 50,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.7,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42.85,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 78.25,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 7.23,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 21.2,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 67,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.6,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 56,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.08,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 89,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34.41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 56,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 29.31,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.86,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 89.36,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 56.98,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 98,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.1,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 69.67,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.8,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 98.99,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.3,
    no_of_parents_spent_31to45mins: 25,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 27.77,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 44.39,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.77,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 67,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 117,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 69.12,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 95,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69.57,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 25,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 42.25,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 69.49,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17.39,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 102,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.45,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 12,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 16,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26.1,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 86,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 41.56,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44.17,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 38.45,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 29.31,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41.56,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 55,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.3,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.1,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 69,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 50,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 16,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21.6,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 32,
    avg_tS_16to30mins: 17.39,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 41.69,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 29,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 29.27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 69.3,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43.94,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 77,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 67,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 69.53,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24.43,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.67,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.17,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 79.98,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.21,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38.61,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 76,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 65,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 65.12,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 60,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 21.78,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.12,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 70.23,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 60,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 114,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 36,
    avg_tS_gte45mins: 104,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 17.72,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.55,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17.72,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.59,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 68,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 29.27,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 103,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40.49,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.44,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38.45,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 55,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 19.9,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 82.23,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42.25,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69.71,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 108,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 56,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42.37,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 69,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 69.37,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 67.98,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40.82,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 86,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 31,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.31,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.45,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.5,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 98.36,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 67.23,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.17,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.4,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42.89,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 65,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 69.46,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45.7,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 45.7,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 89.34,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 65,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 47,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 87,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 41.56,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 47,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 69.33,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 29.76,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 114,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42.12,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 69.49,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 50,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 45.7,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.66,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 90,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44.6,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38.21,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43.43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 30,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 25,
    avg_tS_16to30mins: 29.7,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39.99,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 50,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 31,
    avg_tS_gte45mins: 70,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 14,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 27,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 44.73,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 48.12,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 68,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 94,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 31.67,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 78,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 60,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.05,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 66,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.51,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.98,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 56,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.14,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 69.12,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 65,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 22,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 56.7,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 69.22,
  },
  {
    month: 2,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69.56,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 69.22,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.6,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.65,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 2,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 41.34,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 80,
  },
  {
    month: 2,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43.89,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 2,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 21.98,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 13,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 112,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 98,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 18.3,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 80,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 98,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 78.59,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31.67,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 120,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 48.32,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 19.82,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 23,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 85,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27.62,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 71,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 50,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 98,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 48,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 20,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 113,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 99,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 14,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.56,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 76,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 22,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 103,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 22.31,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 65,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 80,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 16,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 92,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 85,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 56,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 119.98,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 70,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 96,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 91,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 118,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 87.98,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 93,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 96,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 86,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 85,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.3,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 49,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 48,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25.3,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 105,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41.34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 54,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55.96,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 50,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 76.34,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 59,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 50,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 18,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 56,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 28,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 50,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 46,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 39,
    avg_tS_31to45mins: 41.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 62,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 65,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 70,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 53,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 80,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 53,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 115,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.9,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 67,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 33.16,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 52,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 86,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 48,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 11,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 51,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 80,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 53,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 18.5,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 81,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 86,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.39,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 48,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.14,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 67,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28.87,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 67,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 33.34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 85,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 84,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 89.15,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 98,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70.34,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 52,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 82.18,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 13,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 54,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 46,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 85,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 98,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 94.98,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 15,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.99,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 30,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 108,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 70,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 70,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 26.1,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 104,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 98,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.56,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43.8,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.4,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 165,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 28,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 56,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 44.23,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 54,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.12,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 88,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 16,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 19.4,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 98,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 19,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 114,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 51,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 52,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 98,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 11,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 103,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23.67,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 49,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 59,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 90,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 13.3,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 22.8,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 111.11,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 86.19,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 83,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49.45,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 57,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 39,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 111,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.9,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 36.16,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 29.76,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 17,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 37,
    avg_tS_gte45mins: 103,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18.23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.12,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 93,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 83,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 51,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 59,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 49,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18.23,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 50,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 85,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.43,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 57,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 59,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.2,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 51.6,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 80,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 56,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 103,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 59,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 83,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 59.98,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.78,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 71,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.77,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.23,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 47,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 63.39,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 73,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26.89,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 80,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.7,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 52.3,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 31.45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 85,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 58,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 90,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 70,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 93.26,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 70,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 57,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 56.98,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 86,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 103.96,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 19.9,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39.12,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 56,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 66,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 18.88,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 80,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 82,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 56.89,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 25,
    avg_tS_31to45mins: 39.12,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43.34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 54,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34.61,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 71,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 11,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 117.23,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 38,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 113,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12.83,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 24.9,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 53,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39.99,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 56,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 37.98,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 97,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43.4,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 48,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 15,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21.98,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 137,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 48,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 104,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 74,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 86,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 93.67,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 67,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 51,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49.3,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 47,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 98,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 27.8,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 70,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 106,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 44.32,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 27.8,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 95,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 83,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 22,
    avg_tS_16to30mins: 23.65,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 46,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 114,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 31,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 112,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 56,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8.23,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 79,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 54.3,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 51,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 93,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 118,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34.31,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 67.34,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 77.1,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.89,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 58,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 18,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 42,
    avg_tS_31to45mins: 43.3,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.38,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 27,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 79.34,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 28,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 27,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 117,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28.6,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 55,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.12,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 50,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 25,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 56,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 50,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.3,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 21.31,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 97,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33.34,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.56,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 98,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37.28,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 94,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 16,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 76.45,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 73,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 45,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 31,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 71,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.98,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34.67,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 76.11,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 14,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 24,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 37.12,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 56,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.3,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 86,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27.43,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 41,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 89.26,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 66,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 83,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 82,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 82,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 13.78,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.3,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39.2,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 62.7,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 18.3,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 56,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 46,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.32,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 66,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 98.23,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 20,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 109,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 59,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 57,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.34,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 24.41,
    no_of_parents_spent_31to45mins: 25,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41.12,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34.56,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 81,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.8,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 25,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 120.23,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 61.5,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 32.98,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 103,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 34,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.3,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 35.2,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 54.6,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75.38,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 24.4,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 12,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 107,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 53,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 56,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 67.44,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 71,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 66.23,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 98.34,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 26,
    avg_tS_16to30mins: 29.5,
    no_of_parents_spent_31to45mins: 41,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 71,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 48,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 113,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 108,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 56,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 111,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 30,
    avg_tS_gte45mins: 70,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 36.67,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 21.8,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 88.23,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 49,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 12,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 33,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 104,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 30,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 120,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.38,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26.89,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 59,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 11,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33.98,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 112,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.93,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 59,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 56,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 50,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.72,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 42.98,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 70,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25.3,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 3,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.9,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.8,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 47,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 54.12,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39.2,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 90,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.1,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 34,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 87.34,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 38.56,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.56,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 56,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 101,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 37.78,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 122.87,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 13,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 112,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24.44,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 51,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 22,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 81,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 45.22,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 28,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 78.98,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 54,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 19,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 78.45,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 59,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 93,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89.98,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 41.23,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 66,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 68,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 13,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 95,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.31,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 24,
    avg_tS_2to5mins: 8.45,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 97,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 78,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 67,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39.99,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 57,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 118,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 17.77,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40.12,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 16,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 16.9,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 48,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42.4,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 49,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 104,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 74,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 89,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 18,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 73,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 79.98,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 70,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 104,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 50,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 65,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 87,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 106,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 76.91,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 50,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 54.01,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 49,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 11,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 19,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 34,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 67,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 67,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 60,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 48,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 11,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 77,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 12.6,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 32.07,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 50,
  },
  {
    month: 3,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 60,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.39,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 68.88,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.68,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.34,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 31.5,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31.5,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.02,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 46,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.19,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44.02,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 48,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.82,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.81,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.62,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69.62,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.21,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 74,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.91,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44.32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.56,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.65,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 69.31,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 74,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 102,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 137,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.46,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 167,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 134,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 33.34,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 85.89,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.98,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 48,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 116,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 116,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 49,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 98,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 48,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68.72,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.02,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 46,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68.84,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42.29,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 68.84,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.17,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 20.25,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78.72,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 102,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.28,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 46,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 68.91,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.62,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.08,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.66,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.09,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 48,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 50,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68.83,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.63,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.63,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 110,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 96,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.56,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.02,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18.32,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 43.13,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.22,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.23,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 67,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.55,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 52,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 49,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 57,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26.89,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.78,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 113,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 97,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 76,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.66,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.03,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 62,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.99,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 62,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 53,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 98,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 25,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.68,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.29,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.29,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 53,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 57,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 46,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 52,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43.35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 25,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.21,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 87.26,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.67,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75.28,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.05,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 74,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.99,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 89.26,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 43.13,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 83,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25.9,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 48,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42.29,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 74,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 59,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.67,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 51,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 51,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.62,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 52,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.8,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 51,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34.71,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 68.94,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 50,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 48,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 53,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.44,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 46,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17.65,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 86,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18.98,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 20,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 18.88,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 46,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.98,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 53,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 107,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 49,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 52,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.48,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.7,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.42,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.4,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 50,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 51,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 50,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 46,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 51,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 103,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.21,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 117,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.71,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43.59,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 87.87,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 56,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 68.84,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 54.01,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 44.17,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 84,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 98,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 83,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 68.92,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 49,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.5,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 82,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 45.17,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 96.98,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.18,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 69.65,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.7,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.23,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 70.34,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 11.34,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.45,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.29,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.98,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.9,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43.62,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 85,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34.21,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 74,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.72,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.65,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 98,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 17,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29.99,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.14,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 81,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.45,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43.14,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 74,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.76,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 123,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 114,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42.21,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 80,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18.88,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 85,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.81,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.04,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17.88,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.37,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17.89,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 85,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 81,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76.12,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 59.12,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.04,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 104,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 69.14,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.58,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 101,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 68.86,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 68.2,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 103,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 58.1,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41.3,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 53,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.26,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 16,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 25,
    avg_tS_31to45mins: 30.3,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 24,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 19.91,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44.22,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 58.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.43,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 120,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26.1,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 46,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.32,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 48,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44.05,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.21,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.14,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 67,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 46,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.4,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40.33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68.89,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.45,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 22.9,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 103,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26.67,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 78,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 86,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 54.01,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36.78,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 77,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 93,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 76.11,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 89.12,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 69.06,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68.92,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 68.81,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 66.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 62,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68.83,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 68.83,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 82,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76.98,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 83,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.34,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 58,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.23,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43.38,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 68.78,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.56,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 70.34,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.89,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 117.23,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.23,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.65,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 69.31,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 23.98,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 33.34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 81,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 22,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 69.53,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17.72,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.76,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 42.37,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 83,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.91,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 85,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 85,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 103,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.12,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 107,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 47.87,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 112,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 27,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 85,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 27,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 53.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 22,
    avg_tS_16to30mins: 20.98,
    no_of_parents_spent_31to45mins: 34,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 42,
    avg_tS_gte45mins: 69.73,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.8,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.4,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.61,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 25,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.31,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 69.49,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.29,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.39,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.45,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 83,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 39,
    avg_tS_31to45mins: 44.17,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 42.15,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 72,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42.3,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 86,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 103,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 77.92,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 59.23,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 66,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.1,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 58.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 50,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 95,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 101,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 68.87,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 84,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.2,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43.05,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 83,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45.7,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 98,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 44.06,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 69.33,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 68.95,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37.27,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.34,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 68,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 18.9,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 32.34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 69.02,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 17,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34.56,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 29,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 21.31,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34.67,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 80,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18.83,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 113,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 80,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 74,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 37.98,
    no_of_parents_spent_gte45mins: 30,
    avg_tS_gte45mins: 68,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.89,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 113,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 56.7,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68.72,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.89,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 25,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.22,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 18,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 27,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.29,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17.89,
    no_of_parents_spent_31to45mins: 31,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 67.33,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 44.28,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 68.72,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.27,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.4,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.4,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 86,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.7,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45.1,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 86,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.4,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.23,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 38.23,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 67.45,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 76.11,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 66.98,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 68.93,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 23,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.23,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 12.71,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68.78,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.3,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 85,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27.71,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 42.11,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68.82,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42.21,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 62,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 58.1,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 69.22,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 27,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 28,
    avg_tS_16to30mins: 23.14,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 30.6,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.23,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.56,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 94.45,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.98,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.18,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.98,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 44.18,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.31,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 38,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 69.17,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43.12,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 48,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 86,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.01,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 58.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.59,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.39,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.56,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 108,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68.87,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.19,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 68.79,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42.79,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.47,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 68.84,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 54.01,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 78.16,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 44.23,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 98,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 84,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 69.68,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 68.79,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.8,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43.8,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.12,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 98.76,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.56,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.23,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.62,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.08,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 89,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17.6,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 41.7,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 76,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 29.12,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.83,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 134,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 44.4,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 69.1,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76.11,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 68.86,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 34,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.9,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 107,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 43.33,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 107,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.56,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 88,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 23.14,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.14,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44.18,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18.3,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.72,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42.29,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 94.98,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.59,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 74,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 29.21,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 73,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 77,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 46,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.14,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42.92,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.21,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.8,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 69.56,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 95,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 68.79,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 84,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 68.84,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.5,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 22.54,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 85,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 17.72,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 42.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.72,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40.82,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69.36,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 89,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 81,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40.44,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 74,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.17,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 85,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42.21,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 81,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44.4,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 68,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 44.4,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39.99,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 69.68,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 32.98,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 101,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 34,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26.1,
    no_of_parents_spent_31to45mins: 33,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69.73,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43.33,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 93,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 58.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 0,
    avg_tS_2to5mins: 0,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 67,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43.08,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 68.76,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41.19,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 23.67,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69.57,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 18.4,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 84.23,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42.15,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 84,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.56,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 69.19,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 78,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 68.84,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 76.18,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 56.76,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 44.17,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.07,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44.86,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 76.87,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76.98,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.23,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.56,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 60,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.78,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 87.98,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 74,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 33.67,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 85,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 26.72,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40.82,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 99.98,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.4,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29.5,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 41.45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.76,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 31.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 68.85,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.9,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.46,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78.23,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24.7,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 61,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34.33,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 85,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 44.6,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 69.11,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.11,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40.59,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 86,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26.1,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 78.34,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20.98,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32.56,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 65.72,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.78,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 31,
    avg_tS_31to45mins: 43.47,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 68,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 4.56,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 24.6,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 43.47,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27.77,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69.21,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.79,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.47,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 101,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 98,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 34.34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 83,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 98,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 82,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 89.98,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 89.76,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 72,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.61,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17.39,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.09,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.18,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 26.1,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43.89,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 74,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43.89,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 68,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.14,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 62,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 34.6,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68.85,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 53.1,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.56,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 44.22,
    no_of_parents_spent_gte45mins: 38,
    avg_tS_gte45mins: 68.72,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 103,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 58.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 69.23,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 98,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 98.67,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.23,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 69.11,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.11,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 46,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 69,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 77.5,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 107,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.34,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43.47,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 58,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 77,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 101,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 43.23,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 101,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 68.9,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 83,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 98,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 68.93,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 68.95,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 68.81,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 22,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45.19,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 68.93,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.72,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.59,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.39,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 80,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 85,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 21.2,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 62,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 33.98,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 81,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 32.67,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 80,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 30,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 69.23,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.4,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.2,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 69.5,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 77,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 68.8,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 69.19,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 76.11,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 98,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 41.78,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.54,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37.38,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.01,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20.4,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 44.98,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 58.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24.9,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 79,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43.89,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 80,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 69.53,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 13,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 85,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 85,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23.19,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 92,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 69.17,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 77,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 101,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 58.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 21.31,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 69.19,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 82,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 73,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 44.34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 24,
    avg_tS_16to30mins: 23.19,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44.4,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43.33,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 42.12,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 72,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 77,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.9,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 86,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 103,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 84,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 43.47,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 60,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.21,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 44.12,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 77,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 107,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.5,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40.44,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 81,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 76,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 53.1,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 42.88,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 16,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 24,
    avg_tS_16to30mins: 21.98,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.56,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38.87,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 35.6,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 49,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 67,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43.61,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 92.35,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 86,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42.79,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 31.3,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 68.84,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 42.45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 79.16,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 69.33,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 21.9,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 43.4,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 28,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 62,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 12,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23.45,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 30.3,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 72.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40.82,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 74,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76.23,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 98,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3.56,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 20,
    avg_tS_2to5mins: 6.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41.56,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 98,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 74,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 53.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 97,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.36,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 33,
    avg_tS_gte45mins: 69.57,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69.22,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 83,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34.56,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 54.01,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 83,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 4.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 38.21,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 79.19,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 44.17,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.07,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 81,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42.88,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.78,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 4,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43.29,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 69.3,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 20.98,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 96,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 45.7,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 80,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.23,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 71,
  },
  {
    month: 4,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 4,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 56,
  },
  {
    month: 4,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 34,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 31,
    avg_tS_gte45mins: 80,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 82,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 95,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 95,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 104,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 68,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 83.1,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 62.7,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 104,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32.23,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 22,
    avg_tS_2to5mins: 6.4,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 86,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17.65,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.99,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 48,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17.74,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 64,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 75,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 91,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 62,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 62,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 80,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS, TULSIPUR",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41.4,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 67.18,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 99,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 34,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 58,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 82,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 53.1,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 111,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 57.92,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 48,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 50,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 113,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 86,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 106,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39.56,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 107,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 52,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 105,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 48,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74.68,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 101,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 102,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 19,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 16.77,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 118,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 17,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 31.25,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 86,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 46.08,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 52,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 48,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 61.33,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 94,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 96,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 118,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 59,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 29.13,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 50,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.2,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 115,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 57,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 59,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 79,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 114,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 50,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 57,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 92,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 82,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 95,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 86,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 48,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 47,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 105,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 82,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17.39,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 41.87,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 86,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 47,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 52,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 59,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 67,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75.5,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 50,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26.67,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 86,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 47,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 48,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 59,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 81.26,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 47,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 47,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 82,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 83.27,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84.97,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 54.58,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.35,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 48,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 50,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18.81,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 64,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 99,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 52,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 50,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 47,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 48,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.59,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 48,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 105,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 66,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 90,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27.99,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 48,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 52,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 48,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 79,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 50,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 52,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 50,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27.66,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 89,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 79,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 97,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 110,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.4,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 52,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 58.97,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 50,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 57,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 47,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 57,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 67.45,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 63,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 77.5,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 77.5,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.76,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 95,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 65,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 61,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.39,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 65,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 66,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.3,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.8,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 83,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 66,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 95,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 52,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 92,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 58.6,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 68.93,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 99,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 34,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 99,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26.6,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 60,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.9,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.59,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 67,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 96,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 52,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 62.6,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.6,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45.17,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 64,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 91,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 62,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 22,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 66.4,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 36,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 33,
    avg_tS_gte45mins: 80,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 65,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.54,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 117.23,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.38,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 119.98,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 94.98,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 87,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.72,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 55.25,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.45,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 45.17,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 89.98,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.34,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 52.69,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.67,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 58,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.67,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68.1,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.05,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 33.48,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 58,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17.72,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 70.34,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.08,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 88.49,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.12,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 111,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.23,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 50,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 111,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 61,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.4,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 62,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25.34,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 80,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 133,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 115,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 46,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 104,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 110,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS, TULSIPUR",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 107,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76.7,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 61.3,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 53.1,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 67,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 16.18,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 12,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 17.65,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45.09,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 95,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42.59,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 62,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 105,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 65.12,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.02,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 107,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 87,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 61,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 65,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 81.38,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 85,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 93,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 92,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.59,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.4,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 98.23,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.31,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 112,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 66,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 47,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 65,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 78,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 48,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 56.4,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 67,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.75,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 59,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 0,
    avg_tS_2to5mins: 0,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.66,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 54,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 63,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 50,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 47,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 51.47,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 47,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 61,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 47,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 83,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 52,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 63,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.7,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 54,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 41.61,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 89,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 42.31,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 64,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 25.66,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 63,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76.31,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 47,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 63.5,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 105,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 56,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 71.68,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 62,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 106,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 52,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 117,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 58,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 108,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 59,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 95,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 112,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3.6,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 36.12,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 86,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 94,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 52,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 53.34,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.54,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76.11,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 23.19,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 70.1,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 97,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 41.09,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 67.45,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 96,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16.41,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 67,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.09,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 70.34,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 117.23,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 109.98,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 60,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25.59,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.67,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 38.17,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 67.56,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26.67,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 38.99,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 66,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 19.67,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.87,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 84,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 98.23,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17.75,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 105,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 89,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 104,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17.39,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 89,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 106,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 116,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 29.45,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 96,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 66,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 104,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 101,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 106,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 110,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 100,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 79.26,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.9,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 83,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 66,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.57,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 57,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 37.5,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 48.34,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18.91,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 59,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 82.38,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 79,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 66,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 108,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 12,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12.57,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.66,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 93,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.9,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 43.71,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 65.9,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 68.34,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 61,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 59,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 61,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 85,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29.99,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.4,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 67,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 82,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.12,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36.23,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 84,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 104,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 84,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 64.2,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 95,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 68.93,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.98,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 94,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 104,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 45.17,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 104,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 93.67,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.4,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 80,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 46,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 82,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 84.39,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 82.9,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 19.9,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 78.17,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41.94,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 53.67,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 96,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18.72,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 58,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 38.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 82,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 60.66,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 64,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 91,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49.21,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 52,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 104,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.66,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 47,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 87,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.3,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37.2,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 56,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 74.3,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 81.8,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 105,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS, TULSIPUR",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 26.89,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 104,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.15,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42.59,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 94,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.02,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.23,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 87,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 52,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.99,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45.09,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18.3,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 88,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.41,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41.17,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 52,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.6,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 54,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 54.91,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 83,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41.43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 89,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 56,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 97,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 85.27,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 66.4,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 62.91,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 101,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 13,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 87,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68.93,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 66.4,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 94.98,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.09,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 37.56,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 84.3,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 44.12,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 95,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 22,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 79,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 80,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42.21,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 111,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.37,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 99,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26.67,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 110,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 119,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 106,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 67,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.8,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 96,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 36.12,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 81.59,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 13104,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.45,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 94,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 31.34,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41.15,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26.67,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 98,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 59,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 112,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 54,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 55.2,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 78.23,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69.65,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 66,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 79.16,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18.97,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 92,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 91.7,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 79.27,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.09,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 53,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 61,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.39,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 75.4,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 62,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 59,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 16,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 31.45,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42.78,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 91,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 18,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 91,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 62,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33.34,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 84,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 102,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.32,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 102,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 47,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34.23,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 101,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 87,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 93,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 66,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS, TULSIPUR",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41.18,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 67,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.4,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 37.3,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 59.3,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 37.32,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 89,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42.82,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 86,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 42.12,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 84,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 31.95,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 84,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 43.16,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 61,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.54,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 70.37,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 80,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 51,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 80.3,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.34,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 80,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.23,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.21,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34.56,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 93,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 62,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.98,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 117,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 45.09,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 111,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.1,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 107,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 67,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 22.39,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 71.6,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 78,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49.5,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 86,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36.12,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 82,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 67,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 68.93,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 79,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.3,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 62,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 14.34,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 43.12,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.67,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 83,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 87.11,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 102,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 86,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 90,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 49,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 81.3,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 45.09,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 89,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 54.62,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 78,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 81.97,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 95,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 56.4,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 61,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 90,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 61,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 92,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 67,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 73.96,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 78,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.9,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 93,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 88,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 19.7,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 79,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 35,
    avg_tS_gte45mins: 89,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 94,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 80,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 78,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 32,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 32,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 31,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 115,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 112,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 23.41,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 82.82,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 33.9,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 48.9,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 46,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 62.1,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.56,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 65,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29.76,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 97,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 84,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 24,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 42.23,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 80,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 91,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 64,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 49.2,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 46,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 80,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 112,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 29,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 58,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 79,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 187,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 88,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 33,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 39,
    avg_tS_gte45mins: 56,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 35,
    avg_tS_gte45mins: 80,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38.95,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 85.32,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 54,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 23,
    avg_tS_2to5mins: 3.3,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 24.41,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 45.09,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 113,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.4,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 52.6,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 92,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 104,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 91,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 74,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 91,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 84,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 108,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 106,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 108,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 86,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 105,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 78,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 17,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 104,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 85,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78.45,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27.35,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 84,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 16,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 118,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 26,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 64,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 65.9,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 91.4,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 36.65,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 65,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 47,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 22,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34.23,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 70.34,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 32.34,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 94,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 43.21,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 167,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 109.91,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 67.23,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 27.77,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 112,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 62,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.39,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 110,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 54,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 80,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 53.1,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 100,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39.32,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 79,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 38.23,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 67,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 107,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 101,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 73.67,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 86,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.19,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 46.08,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 96,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 78,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 54.01,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 107,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 59,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 87.1,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 92,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.67,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 87,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 82,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 22,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 52,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 87.5,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 102,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 50,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 57.6,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 57.2,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 41.09,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 96,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 103,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 28,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 35.33,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 16.66,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32.34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 93,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42.76,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 84.48,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44.23,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 105,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29.9,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 64,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 17.94,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 91,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 85,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.36,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 101,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 84.8,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 106.11,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 84,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.19,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 66.4,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 94,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 25,
    avg_tS_31to45mins: 53,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 68,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 46,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78.45,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 91,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 58,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 22.8,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 77,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 102,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 64.8,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 78,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 117,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.4,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 104,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 48.28,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.23,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 81,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 36.56,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 32.32,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 73,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 26,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 89,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 53,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 67.56,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 64,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 64,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 46.54,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 64,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 105,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.34,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 44.48,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 87.23,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 85,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 64,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 70,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 81.4,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 28,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 27,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 76,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 129,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 71,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 69,
  },
  {
    month: 5,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25.6,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 42.3,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 56.7,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 97,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.98,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 5,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17.98,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41.09,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 106,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 67,
  },
  {
    month: 5,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 72,
  },
  {
    month: 5,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 39,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 105,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.56,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 45.17,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 94.98,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 20,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 27.43,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 33.56,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 25,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 19,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 99,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43.12,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.7,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 96,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.98,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49.56,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39.99,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 104,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26.8,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32.98,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 101,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.43,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 83,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 94,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 104,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 77.5,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 67,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36.34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78.32,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.54,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 103,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 104,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43.89,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 118,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 116,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 99,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 101,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 66,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.67,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 17,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 61,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 32.98,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 86,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 56,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 120,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 34.56,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 110,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 98.34,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26.62,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 62,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.31,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32.32,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.3,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 54,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 56,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 99.23,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 104,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40.12,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 66,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 31,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 23,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 86,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.85,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 86,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 108,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 42.18,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 50,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.21,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 103,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.36,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 61,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.98,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41.12,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 49.29,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 119,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.14,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 32.56,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 106,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 106,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.31,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.84,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 88.89,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 105,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.45,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 22,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.23,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32.98,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 48,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 81.11,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 52,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 112,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 43,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 14,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 13,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 56,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 95,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.12,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.61,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.16,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 103,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 167,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 29,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 53,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 62,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 67,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 28.98,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 109,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 28,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.77,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 95,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 49.76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 56,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 106,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 46,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34.67,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 44.23,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 87,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 108,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 56,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.52,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 67,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 58,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 41.31,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 94,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34.51,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 88,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 104,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 25,
    avg_tS_31to45mins: 42.56,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 53,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 63.65,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 87,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 31,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.43,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.3,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 36.31,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 108,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.3,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 106,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33.34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 65,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 90,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 62,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 103,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 78.48,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 133,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 22.54,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.77,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 109,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.67,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 89,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34.67,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 86,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 86,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 110,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 118,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43.67,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 107,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 56.98,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 18.91,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 52.9,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 59,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 3.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 18,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 21.21,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 88.34,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 56.45,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87.34,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 62,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 46.34,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 52,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 59.12,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 68.98,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.3,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 67,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS, TULSIPUR",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 23.45,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 88,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS, TULSIPUR",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 78.24,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26.67,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 69.21,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.23,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 108,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 26,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 41.45,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 67.98,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 44.32,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 127,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 112,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 62,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32.71,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 97.37,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.7,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 52,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 59,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 100,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 93.67,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 44.12,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 118,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 113,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.91,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 87,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 13,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 24,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS, TULSIPUR",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 66,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 57,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 48,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 93,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 99,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 106,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17.72,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 36.12,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33.23,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 97,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32.56,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 98.43,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS, TULSIPUR",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44.13,
    no_of_parents_spent_gte45mins: 35,
    avg_tS_gte45mins: 95,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 21.98,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 86,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 110,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 97,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 53,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 53.27,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 86.87,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41.12,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 117,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.99,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 47,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 116,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43.56,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 67,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32.18,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 38.28,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 27,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 88.98,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 104,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.24,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33.3,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 117.23,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 94.21,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 52,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 119,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 124.34,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 89,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 56,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 88,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 112,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 84,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34.56,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 56.45,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 103,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 57,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 67,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 56,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18.88,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 67.45,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 63.68,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 53,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 66,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 53,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 67.34,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 78.32,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 122,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 56,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 64,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 58.12,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 86.23,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17.72,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 47.45,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.84,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.66,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 46.2,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 56,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 92,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 56.53,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 90,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 69.57,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 85.58,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38.34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 102,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 48,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "JAGABANDHU UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JAINABAD CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 105,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "JAINABAD PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 130,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 48,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 46,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 47,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26.74,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.3,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 48,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "JAJALPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 52,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "JANARA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.5,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 47,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JANATA NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43.23,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 33,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "JAGABANDHU UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JAINABAD CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 57,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "JAINABAD PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37.98,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "JAJALPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26.43,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 37.17,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "JANARA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JANATA NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.21,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 45.17,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 65.58,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "JAYADURGA NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "JAYADURGA NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "JAGABANDHU UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "JAYADURGA NODAL UPS, CHAKRAKUDA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37.8,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JAINABAD CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 17.65,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "JHAMPULAKUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "JORAKULA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "JUANLA BADHEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "JAINABAD PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "JAJALPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "JANARA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KAHNAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "JAYADURGA NODAL UPS, CHAKRAKUDA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 83,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KAJIPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.6,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32.6,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49.2,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JANATA NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "JAYADURGA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "JAYADURGA NODAL UPS, CHAKRAKUDA",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 46,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 86,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "JHAMPULAKUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26.8,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "JORAKULA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.31,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "JUANLA BADHEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 58,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45.52,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 134,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18.5,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 52.9,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "JAGABANDHU UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 57,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JAINABAD CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "JAINABAD PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "JAJALPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "JANARA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KAHNAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 48,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KAJIPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32.23,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KAJIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.9,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 113,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "KALAPANCHANA URDU PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 111,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KALIAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 59,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "KAMADARI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 113,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KANAPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.3,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 116,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JANATA NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.34,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 47,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "JAYADURGA NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "JAYADURGA NODAL UPS, CHAKRAKUDA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 22,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "JHAMPULAKUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "JORAKULA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KANTIA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 111,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KARANJISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 41.34,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 112,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "KASIASASAN PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KASIPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 63,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KERANDIABINDHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 52,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 101,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KHAJAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 105,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 34,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "KHARIKUDA MADHYASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 91,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KHEDARAPUR PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 104,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "KHELAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 113,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "JUANLA BADHEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 83,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KAJIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "JHAMPULAKUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KAHNAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "KALAPANCHANA URDU PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 48,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "JORAKULA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KAJIPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.3,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 47.3,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "JUANLA BADHEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOLHANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 112,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KALIAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "KAMADARI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KANAPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KAHNAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KANTIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KARANJISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 33.12,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "KASIASASAN PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KAJIPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KAJIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41.12,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "KALAPANCHANA URDU PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KASIPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KERANDIABINDHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KAJIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KHAJAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KALIAPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 32.71,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "KHARIKUDA MADHYASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KHEDARAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18.4,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87.2,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "KOPINDAPATALIGRAM PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 117,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOTHAKUSANGA CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 59.47,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "KHELAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "KAMADARI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KANAPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "KALAPANCHANA URDU PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KUANARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUMARPADA NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KALIAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "KURUKUNDA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 90,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUSIKONA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.31,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 58.49,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "KUSUMATHENGA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 62.4,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.98,
    no_of_parents_spent_31to45mins: 31,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 94.98,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 89.65,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "LAMBILO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "LAXMINRUSINGH ADARSHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "KAMADARI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KANAPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.73,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18.7,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 38.9,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 53.4,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KANTIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KARANJISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.67,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "KASIASASAN PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KASIPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41.2,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KERANDIABINDHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 10.54,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KHAJAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "LOKEIGORADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17.2,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.4,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 56.2,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.78,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 105,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "KHARIKUDA MADHYASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KHEDARAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.67,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "KHELAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOLHANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "KOPINDAPATALIGRAM PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOTHAKUSANGA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KUANARPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUMARPADA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 59,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 58,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 106,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MAHALO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 60,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 102,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MALIASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.24,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.3,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: "67.23.",
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 32.45,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MANIADIHA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.9,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 67.45,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOLHANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "KURUKUNDA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.67,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 87,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "KOPINDAPATALIGRAM PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUSIKONA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33.8,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 82.3,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOTHAKUSANGA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18.98,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12.34,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 78.14,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KUANARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40.11,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUMARPADA NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "KURUKUNDA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "KUSUMATHENGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 24,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 0,
    avg_tS_2to5mins: 0,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUSIKONA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "KUSUMATHENGA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MITEIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KANTIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KARANJISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "LAMBILO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "KASIASASAN PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KASIPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KERANDIABINDHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49.4,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "LAXMINRUSINGH ADARSHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "LOKEIGORADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "MOHANDAS PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24.41,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 59,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KHAJAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 47,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "KHARIKUDA MADHYASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KHEDARAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 16.2,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "KHELAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOLHANA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "MUKTESWAR PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 114,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 103,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "MURUDI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 56,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 102,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "KOPINDAPATALIGRAM PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOTHAKUSANGA CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KUANARPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUMARPADA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "LAMBILO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "KURUKUNDA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "LAXMINRUSINGH ADARSHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "NAGABASTA NHS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 101,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.9,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 117,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NAHANTARA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NAKADIHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "LOKEIGORADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 23,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MAHALO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MALIASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.78,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.09,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MANIADIHA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 52,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.89,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35.56,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 65,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUSIKONA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.4,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75.4,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "NARAYANI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 83.67,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "NIJOGAKASHOTI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 85,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NILAKANTHA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "NILAKANTHESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 31.67,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 104,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MAHALO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MALIASAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "KUSUMATHENGA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MITEIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MANIADIHA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.39,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17.32,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "LAMBILO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "MOHANDAS PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "MUKTESWAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "LAXMINRUSINGH ADARSHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 48,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "LOKEIGORADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MITEIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "MOHANDAS PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "MUKTESWAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.4,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 64.7,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MAHALO PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "MURUDI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MALIASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 53,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 32,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MANIADIHA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "NAGABASTA NHS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.91,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 35.16,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 35.86,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NAHANTARA CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NAKADIHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.4,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 31.8,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 62.3,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "NIMAPARA BAZAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.4,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33.2,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 64.8,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "NUAGAON PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 56,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NUAPATNA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MITEIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.3,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.6,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46.7,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "MURUDI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "NAGABASTA NHS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.92,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "NARAYANI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NAHANTARA CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.61,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NAKADIHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.83,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "NARAYANI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 32,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "NIJOGAKASHOTI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NILAKANTHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.67,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "NILAKANTHESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.56,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 99.21,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "NUASANTHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 59,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.12,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ODAGUAN PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41.89,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49.23,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "ODUAL PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 56,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "OHALA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.67,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 59.78,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28.98,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "NIJOGAKASHOTI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 0,
    avg_tS_2to5mins: 0,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "MOHANDAS PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.4,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 65.3,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "NIMAPARA BAZAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.56,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "MUKTESWAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "MURUDI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NILAKANTHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "NAGABASTA NHS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "NILAKANTHESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NAHANTARA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "NIMAPARA BAZAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NAKADIHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "NARAYANI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "NUAGAON PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NUAPATNA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 53,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "NUASANTHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ODAGUAN PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41.83,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "NIJOGAKASHOTI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "ODUAL PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NILAKANTHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 0,
    avg_tS_2to5mins: 0,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "NILAKANTHESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "OHALA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "OLAMARA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "OLANSHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74.6,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "OLAMARA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.56,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 95,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "NIMAPARA BAZAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "OLANSHA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "NUAGAON PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 52,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "OLIKANA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43.12,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 113,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "ORAPAT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 92,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "OLIKANA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 104,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.39,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "ORAPAT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NUAPATNA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "NUASANTHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ODAGUAN PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.77,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "ODUAL PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "OHALA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "OLAMARA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "PABITRA HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 94,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "PADA GUATIRA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 106,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "OLANSHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 48,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "OLIKANA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "ORAPAT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "PABITRA HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "PADA GUATIRA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 83,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 87,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PAIKSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 57,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PAIKSAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.1,
    no_of_parents_spent_16to30mins: 32,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "PALASUDHA PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.23,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.12,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.65,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 115,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "PABITRA HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "PALASUDHA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.4,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 141,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PANCHAYAT NODAL UP SCHOOL, PATAPUR",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 89,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "PANCHAYAT NODAL UPS, RENGHALO",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 119,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "PADA GUATIRA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "NUAGAON PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PAIKSAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 45,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "PALASUDHA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NUAPATNA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 33,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PANCHAYAT NODAL UP SCHOOL, PATAPUR",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "PANCHAYAT NODAL UPS, RENGHALO",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 48,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "NUASANTHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PANCHAYAT NODAL UP SCHOOL, PATAPUR",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PATAPUR DEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ODAGUAN PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "ODUAL PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "OHALA NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "OLAMARA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "OLANSHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "OLIKANA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "PIPALIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "PODANA TUANSAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67.45,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PRACTISING UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "ORAPAT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 97,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 66.4,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 117,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "PANCHAYAT NODAL UPS, RENGHALO",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.78,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PATAPUR DEULI PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.67,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.78,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 115,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.19,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.98,
    no_of_parents_spent_16to30mins: 28,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PATAPUR DEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 52,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 66.4,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "PIPALIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "PODANA TUANSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.2,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33.4,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 65.4,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PRACTISING UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 94,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.31,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 16.9,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "PIPALIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "PODANA TUANSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PRACTISING UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 48,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "PURUSANDHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 104,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "PURUSOTTAM NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 76.23,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "PABITRA HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "PADA GUATIRA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 1,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.2,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 62.3,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RAIDBAZAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34.56,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 113,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PAIKSAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "PALASUDHA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "RAJARASI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 102,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "PURUSANDHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "PURUSOTTAM NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37.14,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 32,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RAIDBAZAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PANCHAYAT NODAL UP SCHOOL, PATAPUR",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "PANCHAYAT NODAL UPS, RENGHALO",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "RATILO UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 91,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PATAPUR DEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38.81,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "PURUSANDHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25.43,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RENCH SASAN CPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 87,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "RENGHALO HINDU PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 103,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "RESINGA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 103,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SADERIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.13,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 72.23,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "PURUSOTTAM NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.67,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RAIDBAZAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.77,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "RAJARASI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24.6,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 67,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "RATILO UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 52,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RENCH SASAN CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.87,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "RENGHALO HINDU PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "RESINGA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SADERIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.56,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADADEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADADEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 52,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SAHADAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "SAINSA SASAN CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SALAJANGA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.89,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 93,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SALANGA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 108,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 78.23,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SAMARAISHASAN PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 94.11,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "RAJARASI PROJECT PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 46,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "RATILO UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "PIPALIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SAHADAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RENCH SASAN CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "RENGHALO HINDU PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "RESINGA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "PODANA TUANSAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SADERIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PRACTISING UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "PURUSANDHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADADEULI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "PURUSOTTAM NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SAHADAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "SAINSA SASAN CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SALAJANGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33.57,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SALANGA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RAIDBAZAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 32.12,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "SAMBHU BHARATIPATNA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.45,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 106,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SAMARAISHASAN PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 18.81,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "RAJARASI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SANDHAGORADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 167,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANDHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "SAMBHU BHARATIPATNA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "SAINSA SASAN CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "RATILO UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SALAJANGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.78,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SALANGA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.45,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SAMARAISHASAN PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "SAMBHU BHARATIPATNA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.61,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SANDHAGORADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANKHAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 108,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANDHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.56,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 22.31,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 56.98,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "SARBAPADA PRADHANSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 109,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RENCH SASAN CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "RENGHALO HINDU PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SANDHAGORADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "RESINGA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANDHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANKHAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SADERIA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 53,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATAPURI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 59,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "SARBAPADA PRADHANSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATAPURI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADADEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATKABAD PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17.65,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 95,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANKHAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATKABAD PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 47,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SAHADAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "SAINSA SASAN CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SERAGARH BASTI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SALAJANGA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SALANGA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SAMARAISHASAN PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "SAMBHU BHARATIPATNA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39.2,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SERAGARH BASTI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.31,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35.32,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 59.26,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 19,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "SARBAPADA PRADHANSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATAPURI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.28,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATKABAD PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SERAGARH BASTI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "SRC NHS BHANTIGRAMA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "SRIMUKHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.49,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SANDHAGORADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.3,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 22.9,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 44.98,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "SRC NHS BHANTIGRAMA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "SRIMUKHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.56,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.6,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78.9,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "SRC NHS BHANTIGRAMA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 107,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "SRIMUKHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANDHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANKHAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45.17,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "SARBAPADA PRADHANSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.03,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATAPURI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATKABAD PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 54,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SERAGARH BASTI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.34,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 101,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 52.26,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 23,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 45,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "SRC NHS BHANTIGRAMA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 53,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 12,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 18,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 30,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 87.11,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "SRIMUKHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 87,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 49,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "SUNINDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "SUNUGORADI NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "TADHANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALAANDHIA PROJECT UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "SUNINDA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 91,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.98,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 82,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "SUNUGORADI NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 86,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "TADHANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALAANDHIA PROJECT UP SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.98,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 102,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALATANALA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "SUNINDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "SUNUGORADI NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "TARADPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 41.72,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 97.16,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALATANALA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.56,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 39,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 41,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "TADHANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALAANDHIA PROJECT UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "TARADPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "SUNINDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALATANALA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "TARADPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "SUNUGORADI NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "TADHANA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TELISAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TELISAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.77,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 56,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 68,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 97,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TELISAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "TENTULIGUAN UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "TERUNDIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "TENTULIGUAN UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 84,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "THAKURPATNA CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "TIHULA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "TERUNDIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18.91,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 98,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "THAKURPATNA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41.34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "TIHULA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "TENTULIGUAN UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALAANDHIA PROJECT UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "TERUNDIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 46,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALATANALA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "THAKURPATNA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 70,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "TARADPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TELISAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "TENTULIGUAN UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "TERUNDIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "TIHULA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "THAKURPATNA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TORIHANBANDHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 52,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "TOTASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.4,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 46,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.41,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TORIHANBANDHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "TOTASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "TULASIPUR CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "UCHHUPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 81,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "TULASIPUR CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.6,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 88,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TORIHANBANDHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "TOTASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "TIHULA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TORIHANBANDHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "TULASIPUR CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "UCHHUPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.4,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "UCHHUPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34.7,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "TOTASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "TULASIPUR CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "UCHHUPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 115,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "UHANGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.2,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 117,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "UMAMAHESWARPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 0,
    avg_tS_2to5mins: 0,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36.4,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 65,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "UHANGA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "UMAMAHESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 61,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 76,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "UHANGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 103,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "UMAMAHESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 38.4,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "VILISASAN PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 105,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "VILISASAN PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 64,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "UHANGA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 73,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "UMAMAHESWARPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 80,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 6,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "VILLIGRAM PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33.2,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 75,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 71,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "VILISASAN PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.45,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "VILISASAN PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 72,
  },
  {
    month: 6,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "VILLIGRAM PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 79,
  },
  {
    month: 6,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "VILLIGRAM PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 22,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 77,
  },
  {
    month: 6,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "VILLIGRAM PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 94,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 50,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 76.11,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 19,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17.98,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 135,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 93,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43.23,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 107,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 91,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42.21,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27.76,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 38.37,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.23,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 32.45,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60.75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 27.89,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 108,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.99,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.78,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 21.83,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 112,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 57,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 94,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 41.12,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33.48,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 99,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34.58,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 95,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18.9,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 97,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 56,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 41.23,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 35.11,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 59,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 59,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 32.56,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 32.67,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 94.45,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 77,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36.56,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 62,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 95,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 103,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 78.59,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 93.67,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.32,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 67,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS, TULSIPUR",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 67.45,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 32.45,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.7,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 37.78,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 111,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37.17,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 59,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32.37,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 112,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 56,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 36.17,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 73,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 12,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 99.98,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 69.57,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 65,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.3,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 107,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69.57,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.4,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 69.57,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 105,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25.3,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 112,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.7,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17.3,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 49.2,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "JAGABANDHU UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JAINABAD CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 1,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "JAINABAD PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "JAJALPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "JANARA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JANATA NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25.55,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "JAYADURGA NODAL UPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "JAYADURGA NODAL UPS, CHAKRAKUDA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.43,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23.45,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "JHAMPULAKUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "JORAKULA PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "JUANLA BADHEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KAHNAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KAJIPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KAJIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "KALAPANCHANA URDU PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 95,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KALIAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "KAMADARI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KANAPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 101,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KANTIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KARANJISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.56,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "KASIASASAN PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.91,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KASIPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KERANDIABINDHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KHAJAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41.56,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "KHARIKUDA MADHYASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KHEDARAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42.3,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "KHELAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOLHANA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "KOPINDAPATALIGRAM PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.57,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOTHAKUSANGA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KUANARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUMARPADA NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "KURUKUNDA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37.98,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUSIKONA CPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "KUSUMATHENGA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "LAMBILO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "LAXMINRUSINGH ADARSHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "LOKEIGORADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.3,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 47,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 59,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 113,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MAHALO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MALIASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40.31,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MANIADIHA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41.71,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MITEIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "MOHANDAS PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 59,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "MUKTESWAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "MURUDI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "NAGABASTA NHS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.6,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33.41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NAHANTARA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NAKADIHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "NARAYANI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 20,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "NIJOGAKASHOTI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NILAKANTHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "NILAKANTHESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.56,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "NIMAPARA BAZAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27.56,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "NUAGAON PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 54,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 47,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NUAPATNA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "NUASANTHA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ODAGUAN PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "ODUAL PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "OHALA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "OLAMARA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "OLANSHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "OLIKANA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "ORAPAT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 57.34,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "PABITRA HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "PADA GUATIRA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PAIKSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 28,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "PALASUDHA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PANCHAYAT NODAL UP SCHOOL, PATAPUR",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "PANCHAYAT NODAL UPS, RENGHALO",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 27,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 115,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 77,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PATAPUR DEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 116,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "PIPALIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "PODANA TUANSAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PRACTISING UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "PURUSANDHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "PURUSOTTAM NODAL UPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RAIDBAZAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "RAJARASI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.3,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "RATILO UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RENCH SASAN CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "RENGHALO HINDU PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "RESINGA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SADERIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.67,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36.12,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADADEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SAHADAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "SAINSA SASAN CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SALAJANGA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.65,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SALANGA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 81,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SAMARAISHASAN PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 74,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "SAMBHU BHARATIPATNA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SANDHAGORADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANDHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANKHAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "SARBAPADA PRADHANSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATAPURI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.5,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41.39,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATKABAD PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SERAGARH BASTI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "SRC NHS BHANTIGRAMA",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "SRIMUKHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 104,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 36,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 36,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "SUNINDA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "SUNUGORADI NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "TADHANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALAANDHIA PROJECT UP SCHOOL",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALATANALA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "TARADPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TELISAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 105,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "TENTULIGUAN UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "TERUNDIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "THAKURPATNA CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "TIHULA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51.23,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.45,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TORIHANBANDHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 0,
    avg_tS_2to5mins: 0,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 0,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 65,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "TOTASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "TULASIPUR CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "UCHHUPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 36.4,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 106,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "UHANGA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.3,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35.7,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "UMAMAHESWARPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.4,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37.4,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "VILISASAN PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "VILLIGRAM PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 36.7,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 95,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 38,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 18,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 105,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87.34,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 71,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76.11,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 102,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.23,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 101,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43.23,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 38.82,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.9,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 37.34,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 48.98,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 76,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.56,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 94,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.21,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 97,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 107,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45.17,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 107,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 33.55,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 67,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18.3,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 118,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 43.12,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 70,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 47,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 107,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 101,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 104,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 52,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 95,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 103,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.43,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 36.59,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18.23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 108,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 22,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 65.23,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.34,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 107,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 74,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 29.5,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 93.67,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 44.23,
    no_of_parents_spent_gte45mins: 37,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 99,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 47,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 47,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.58,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 31.49,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS, TULSIPUR",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 90,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 47,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 47,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 70,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44.45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 103,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.87,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 79.98,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 47,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 83.67,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 52,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 52,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 14,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 16,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.98,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 73.27,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 90,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 36.19,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.4,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.1,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 65.8,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.34,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 103,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "JAGABANDHU UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JAINABAD CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "JAINABAD PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "JAJALPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "JANARA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JANATA NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "JAYADURGA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "JAYADURGA NODAL UPS, CHAKRAKUDA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "JHAMPULAKUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "JORAKULA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "JUANLA BADHEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.89,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 98.34,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KAHNAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KAJIPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 105,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KAJIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 95,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "KALAPANCHANA URDU PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 95,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KALIAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "KAMADARI PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 88.98,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KANAPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 117,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KANTIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KARANJISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.37,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "KASIASASAN PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KASIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KERANDIABINDHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KHAJAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 32.8,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 52,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "KHARIKUDA MADHYASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.78,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KHEDARAPUR PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.4,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "KHELAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOLHANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "KOPINDAPATALIGRAM PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOTHAKUSANGA CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KUANARPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 70,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUMARPADA NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36.58,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "KURUKUNDA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUSIKONA CPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.3,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "KUSUMATHENGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 62,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 107,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "LAMBILO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "LAXMINRUSINGH ADARSHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "LOKEIGORADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26.4,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 65,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 65,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MAHALO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MALIASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MANIADIHA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 71,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 105,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.12,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MITEIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 57,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "MOHANDAS PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "MUKTESWAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 82,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 103,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "MURUDI PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "NAGABASTA NHS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38.12,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NAHANTARA CPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NAKADIHA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "NARAYANI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 94,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "NIJOGAKASHOTI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41.3,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NILAKANTHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "NILAKANTHESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 69,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "NIMAPARA BAZAR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "NUAGAON PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NUAPATNA PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.45,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 31.9,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 71,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "NUASANTHA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ODAGUAN PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "ODUAL PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "OHALA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "OLAMARA PROJECT PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.45,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "OLANSHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "OLIKANA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.21,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "ORAPAT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.25,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 50.32,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "PABITRA HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.65,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "PADA GUATIRA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 65.8,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PAIKSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 29.5,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "PALASUDHA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 93,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PANCHAYAT NODAL UP SCHOOL, PATAPUR",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "PANCHAYAT NODAL UPS, RENGHALO",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25.3,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.32,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 91,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17.7,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PATAPUR DEULI PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "PIPALIA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 104,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "PODANA TUANSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 72,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PRACTISING UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.12,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 73,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.15,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "PURUSANDHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 66.4,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "PURUSOTTAM NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RAIDBAZAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 79,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "RAJARASI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.7,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "RATILO UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RENCH SASAN CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 72,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "RENGHALO HINDU PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "RESINGA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SADERIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADADEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SAHADAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 82,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "SAINSA SASAN CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SALAJANGA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SALANGA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 59,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SAMARAISHASAN PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "SAMBHU BHARATIPATNA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SANDHAGORADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 97,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANDHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32.56,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANKHAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "SARBAPADA PRADHANSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATAPURI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATKABAD PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SERAGARH BASTI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 49.21,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "SRC NHS BHANTIGRAMA",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "SRIMUKHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 67,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "SUNINDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.12,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "SUNUGORADI NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 107,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "TADHANA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALAANDHIA PROJECT UP SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALATANALA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "TARADPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 4.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 67.41,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 56.58,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TELISAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "TENTULIGUAN UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "TERUNDIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "THAKURPATNA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 62,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "TIHULA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 63,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TORIHANBANDHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 65,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "TOTASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "TULASIPUR CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "UCHHUPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "UHANGA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "UMAMAHESWARPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 0,
    avg_tS_2to5mins: 0,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.2,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.1,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "VILISASAN PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36.7,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "VILLIGRAM PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 92,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 34,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 30,
    avg_tS_gte45mins: 97,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37.78,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 137,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67.28,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.34,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37.98,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 50,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 88.23,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 93,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 94,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32.98,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39.21,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 83.67,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 112,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 73.54,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 56,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 19.32,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 37.23,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 32.21,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49.34,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60.75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 43.23,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.7,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37.98,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 108,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.67,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 106,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 90.78,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 105,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 104,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 95,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 117,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 52,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 37.98,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 70,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 105,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 56,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.89,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 106,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17.65,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 29.5,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 43.23,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 99,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.54,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 57,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.39,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS, TULSIPUR",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 105,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 39.87,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 70,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25.3,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 54,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 88.98,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 11,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 23.14,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.56,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 107,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35.6,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 38,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 104,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 107,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 111.11,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 80,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 25,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 37,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 70,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 91.33,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78.25,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.71,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 47,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 70,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "JAGABANDHU UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JAINABAD CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.12,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "JAINABAD PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "JAJALPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "JANARA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36.27,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JANATA NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "JAYADURGA NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.57,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "JAYADURGA NODAL UPS, CHAKRAKUDA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "JHAMPULAKUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "JORAKULA PROJECT UPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "JUANLA BADHEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KAHNAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KAJIPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.2,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KAJIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.12,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "KALAPANCHANA URDU PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 95,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KALIAPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33.34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "KAMADARI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KANAPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 52,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KANTIA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35.16,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KARANJISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.98,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.11,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 78.23,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "KASIASASAN PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KASIPUR PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KERANDIABINDHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KHAJAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 115,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "KHARIKUDA MADHYASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KHEDARAPUR PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "KHELAR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOLHANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "KOPINDAPATALIGRAM PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.43,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOTHAKUSANGA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KUANARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUMARPADA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "KURUKUNDA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUSIKONA CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41.4,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "KUSUMATHENGA PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 62,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18.6,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 32.4,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 45.1,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "LAMBILO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "LAXMINRUSINGH ADARSHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "LOKEIGORADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.3,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 45.5,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MAHALO PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MALIASAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 34,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MANIADIHA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.23,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MITEIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "MOHANDAS PROJECT PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "MUKTESWAR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 115,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "MURUDI PROJECT PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.12,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 76.18,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "NAGABASTA NHS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 37.56,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NAHANTARA CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NAKADIHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 105,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "NARAYANI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "NIJOGAKASHOTI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NILAKANTHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "NILAKANTHESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 107,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "NIMAPARA BAZAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.2,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "NUAGAON PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NUAPATNA PROJECT PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "NUASANTHA PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ODAGUAN PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "ODUAL PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 50,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "OHALA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 0,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "OLAMARA PROJECT PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "OLANSHA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.3,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "OLIKANA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "ORAPAT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "PABITRA HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 32.56,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "PADA GUATIRA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38.87,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PAIKSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 24,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "PALASUDHA PROJECT PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 57,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PANCHAYAT NODAL UP SCHOOL, PATAPUR",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "PANCHAYAT NODAL UPS, RENGHALO",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 22,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.87,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 103,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 27.43,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34.61,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PATAPUR DEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.3,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.4,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 95,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 59,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 52,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "PIPALIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "PODANA TUANSAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PRACTISING UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.78,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "PURUSANDHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37.98,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "PURUSOTTAM NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 103,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 89.29,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RAIDBAZAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.23,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "RAJARASI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "RATILO UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RENCH SASAN CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 83.18,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "RENGHALO HINDU PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "RESINGA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SADERIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35.6,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADADEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SAHADAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "SAINSA SASAN CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SALAJANGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SALANGA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SAMARAISHASAN PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 18,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "SAMBHU BHARATIPATNA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SANDHAGORADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41.56,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANDHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44.56,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANKHAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "SARBAPADA PRADHANSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATAPURI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41.56,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATKABAD PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SERAGARH BASTI PROJECT PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 49,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "SRC NHS BHANTIGRAMA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "SRIMUKHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 96.37,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 77,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 59,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 19,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 34,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "SUNINDA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "SUNUGORADI NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 119,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "TADHANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALAANDHIA PROJECT UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALATANALA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 64.27,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "TARADPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TELISAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "TENTULIGUAN UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "TERUNDIA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "THAKURPATNA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 80,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 62,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "TIHULA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 63,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.01,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TORIHANBANDHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "TOTASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "TULASIPUR CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 46,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "UCHHUPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.4,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "UHANGA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 26.5,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "UMAMAHESWARPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.2,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32.8,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "VILISASAN PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.4,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36.3,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "VILLIGRAM PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.03,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 22,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25.3,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 70,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.9,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 98.45,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 93,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 103,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 68.84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 73,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 54.01,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 111,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36.34,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32.56,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60.75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 93,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29.5,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 37.78,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 76,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 19,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 82,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.4,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 93.36,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 71,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 95,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 42.11,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 67,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 70.34,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 59.46,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42.12,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 76,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS, TULSIPUR",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 71,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 133,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 144,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.67,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 117.23,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.67,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 94.98,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 52,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 91,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 47,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 52,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 70,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24.2,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37.23,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 51,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 69.57,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 107,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "JAGABANDHU UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JAINABAD CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.45,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.34,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33.3,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "JAINABAD PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "JAJALPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "JANARA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JANATA NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "JAYADURGA NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 88,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "JAYADURGA NODAL UPS, CHAKRAKUDA",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "JHAMPULAKUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "JORAKULA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "JUANLA BADHEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.12,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39.2,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KAHNAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KAJIPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KAJIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.6,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "KALAPANCHANA URDU PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 95,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KALIAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 68.92,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "KAMADARI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KANAPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KANTIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KARANJISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "KASIASASAN PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 68.49,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KASIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KERANDIABINDHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36.12,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 46,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 25.3,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KHAJAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 44.67,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "KHARIKUDA MADHYASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 0,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KHEDARAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 52.4,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "KHELAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOLHANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "KOPINDAPATALIGRAM PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.57,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOTHAKUSANGA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 67,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KUANARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.57,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUMARPADA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39.21,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 90,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "KURUKUNDA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUSIKONA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "KUSUMATHENGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 62,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 63,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "LAMBILO PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "LAXMINRUSINGH ADARSHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "LOKEIGORADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.2,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 50.1,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MAHALO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 76,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MALIASAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MANIADIHA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 76,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.2,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MITEIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "MOHANDAS PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38.2,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "MUKTESWAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33.4,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "MURUDI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "NAGABASTA NHS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43.43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68.93,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NAHANTARA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NAKADIHA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 59,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "NARAYANI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 66.4,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 39,
    avg_tS_gte45mins: 104,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "NIJOGAKASHOTI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.8,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NILAKANTHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "NILAKANTHESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "NIMAPARA BAZAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 76,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "NUAGAON PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26.3,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 0,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 47,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NUAPATNA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.43,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "NUASANTHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ODAGUAN PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 68.93,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "ODUAL PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "OHALA NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "OLAMARA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.98,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "OLANSHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.2,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "OLIKANA UGUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "ORAPAT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 96,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "PABITRA HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "PADA GUATIRA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.1,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PAIKSAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 18.88,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "PALASUDHA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.43,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43.23,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PANCHAYAT NODAL UP SCHOOL, PATAPUR",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "PANCHAYAT NODAL UPS, RENGHALO",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38.29,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 31,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 50,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PATAPUR DEULI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.3,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "PIPALIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "PODANA TUANSAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PRACTISING UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 80,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 68.93,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "PURUSANDHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 104,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "PURUSOTTAM NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RAIDBAZAR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 81,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "RAJARASI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.6,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "RATILO UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RENCH SASAN CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "RENGHALO HINDU PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "RESINGA UGUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SADERIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 63.8,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADA PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADADEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SAHADAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 71,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "SAINSA SASAN CPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 68.84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SALAJANGA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.56,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SALANGA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.2,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49.2,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SAMARAISHASAN PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 82,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "SAMBHU BHARATIPATNA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SANDHAGORADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANDHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.67,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANKHAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "SARBAPADA PRADHANSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATAPURI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATKABAD PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 22,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SERAGARH BASTI PROJECT PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 97,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 89,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "SRC NHS BHANTIGRAMA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "SRIMUKHA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 93,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 61,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 18,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 39,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 48,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "SUNINDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 98,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "SUNUGORADI NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 49,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "TADHANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALAANDHIA PROJECT UP SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALATANALA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "TARADPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 73,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TELISAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 85,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 41.78,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "TENTULIGUAN UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "TERUNDIA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "THAKURPATNA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 62,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "TIHULA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TORIHANBANDHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36.2,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "TOTASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 53,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "TULASIPUR CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "UCHHUPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.7,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 103,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "UHANGA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 86,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "UMAMAHESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 83,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25.7,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "VILISASAN PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 0,
    avg_tS_2to5mins: 0,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 7,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "VILLIGRAM PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 112,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 57,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 17,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 94,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43.78,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 99,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 17,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 135,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 96,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 12,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 43.23,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 17,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 93,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.76,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 107,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42.21,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 91,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 38.37,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.23,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 53,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.89,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 29.99,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.83,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 12,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 26,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 43.78,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 60.75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 108,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 32,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41.12,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 112,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33.48,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 57,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 94,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 99,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18.9,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34.58,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 96,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 95,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 97,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 41.23,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35.11,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 32.56,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32.67,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 88,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 94.45,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 26,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 135,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 96,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 19.32,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 93,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 107,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 91,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37.78,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37.17,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 53,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18.9,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 32.37,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 12,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60.75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 108,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 56,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 36.17,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 112,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 57,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 94,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 99,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 96,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 95,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.32,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 97,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 12,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 32,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 30,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 19.32,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 25,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 88,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 94.45,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27.7,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41.12,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33.48,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 51,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 19,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 26,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 95,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78.59,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 51,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 93.67,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34.58,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 67.45,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41.23,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35.11,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78.98,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 90,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 101,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 78.98,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 32.56,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 145,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32.67,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 104,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 93.45,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 115,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 105,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 0,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 67.98,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 94,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 101,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.3,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 104,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 92.26,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 102.87,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 54.4,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.4,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 93.65,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 88.98,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 25.3,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.7,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89.45,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "JAGABANDHU UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 66,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JAINABAD CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67.14,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "JAINABAD PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "JAJALPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 17.3,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "JANARA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32.45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JANATA NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 0,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "JAYADURGA NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 99,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "JAYADURGA NODAL UPS, CHAKRAKUDA",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37.78,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 101,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "JHAMPULAKUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 107,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "JORAKULA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 37.17,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 67.98,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "JUANLA BADHEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 110,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KAHNAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 104,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KAJIPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25.55,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KAJIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 0,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "KALAPANCHANA URDU PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 27.43,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 88.23,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KALIAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.45,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "KAMADARI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KANAPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 32.37,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KANTIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49.98,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KARANJISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "KASIASASAN PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 117,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KASIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 120.23,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KERENDIABINDHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 0,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KHAJAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 0,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 104,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "KHARIKUDA MADHYASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 92,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KHEDARAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 13,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 63.67,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "KHELAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 56,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 111.11,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOLHANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 0,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "KOPINDAPATALIGRAM PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 36.17,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 107,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOTHAKUSANGA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 103.67,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KUANARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 89.34,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUMARPADA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 107,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "KURUKUNDA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUSIKONA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 97.45,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "KUSUMATHENGA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 29.91,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "LAMBILO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 56.34,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "LAXMINRUSINGH ADARSHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "LOKEIGORADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 73.65,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MAHALO PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 0,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MALIASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 93.98,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MANIADIHA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 139.27,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 0,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MITEIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "MOHANDAS PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "MUKTESWAR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "MURUDI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "NAGABASTA NHS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NAHANTARA CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NAKADIHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "NARAYANI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "NIJOGAKASHOTI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NILAKANTHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "NILAKANTHESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 51,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "NIMAPARA BAZAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 52,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "NUAGAON PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.5,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NUAPATNA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 22.3,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "NUASANTHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ODAGUAN PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "ODUAL PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 52,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "OHALA NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "OLAMARA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.62,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "OLANSHA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "OLIKANA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "ORAPAT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "PABITRA HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 23,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 66,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "PADA GUATIRA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PAIKSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "PALASUDHA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41.12,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 104,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PANCHAYAT NODAL UP SCHOOL, PATAPUR",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 33.48,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "PANCHAYAT NODAL UPS, RENGHALO",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PATAPUR DEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "PIPALIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "PODANA TUANSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PRACTISING UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "PURUSANDHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "PURUSOTTAM NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24.6,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 53,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RAIDBAZAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "RAJARASI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 94.11,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "RATILO UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 51,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RENCH SASAN CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 34.58,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 69.09,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "RENGHALO HINDU PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87.11,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "RESINGA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SADERIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 65.12,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69.32,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADADEULI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SAHADAPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27.56,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "SAINSA SASAN CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SALAJANGA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.56,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 53,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SALANGA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SAMARAISHASAN PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41.23,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 69.3,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "SAMBHU BHARATIPATNA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SANDHAGORADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35.11,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANDHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANKHAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "SARBAPADA PRADHANSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.1,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATAPURI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32.56,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATKABAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32.67,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SERAGARH BASTI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "SRC NHS BHANTIGRAMA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "SRIMUKHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "SUNINDA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "SUNUGORADI NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "TADHANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALAANDHIA PROJECT UP SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALATANALA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 47,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "TARADPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 51,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TELISAHI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "TENTULIGUAN UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "TERUNDIA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "THAKURPATNA CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 21,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "TIHULA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 49.7,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TORIHANBANDHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "TOTASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "TULASIPUR CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "UCHHUPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 69.08,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "UHANGA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 94,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "UMAMAHESWARPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "VILISASAN PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 1,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "VILLIGRAM PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 51,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 95,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 32.45,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 21.3,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37.17,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 83.65,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.4,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 0,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32.37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.18,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.65,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 28,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 58,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 24,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 58,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 56,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 115,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36.17,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 105,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 58,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 107,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.32,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.63,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.45,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78.5,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 69.3,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24.45,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87.23,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 0,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 66,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29.3,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 20.4,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 52,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 58,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 108,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 105,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.3,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.4,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 69.14,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.3,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 69.53,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 69.5,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.5,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 66,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69.6,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 33.34,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 32.67,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 115,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39.12,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.45,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.32,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 105,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.1,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.23,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.37,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 71.34,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37.98,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 49.8,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.3,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.32,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.45,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40.43,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37.28,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 66,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 104,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.31,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39.12,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 58,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 29.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.62,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 5.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 52,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.68,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.47,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 90.56,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 0,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.32,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39.99,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 45,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.67,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 32.72,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.29,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24.45,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 0,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 45.48,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.45,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 66,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 29.3,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.4,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69.53,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.12,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 98.23,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67.87,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.3,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33.34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 88,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.32,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 69.57,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.98,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.21,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.23,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 19.7,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 47,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 69.06,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 19,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 22.39,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 48,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 69.2,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 69.46,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42.75,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26.66,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 69.1,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 69.56,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.98,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 45.17,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.18,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 25,
    avg_tS_31to45mins: 43.55,
    no_of_parents_spent_gte45mins: 30,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.9,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 54,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33.98,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 115,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 32.67,
    no_of_parents_spent_gte45mins: 45,
    avg_tS_gte45mins: 105,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 69.23,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 108,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 37,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27.8,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 95,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 108,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 34.46,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69.49,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 21.31,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 69.11,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.4,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18.9,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.89,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.56,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.19,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 66.4,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 115,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.66,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 112,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 20,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 36.15,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.02,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 78.25,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.02,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 31.78,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 89.36,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.4,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 56.98,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 44.43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.02,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.67,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "JAGABANDHU UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 98.99,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JAINABAD CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "JAINABAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 30.56,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "JAJALPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "JANARA PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JANATA NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.17,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 117,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "JAYADURGA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 69.12,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "JAYADURGA NODAL UPS, CHAKRAKUDA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.37,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 95,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "JHAMPULAKUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.39,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 69.57,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "JORAKULA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 21.89,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "JUANLA BADHEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 69.49,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KAHNAPUR PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KAJIPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 102,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KAJIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "KALAPANCHANA URDU PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 69.45,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KALIAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "KAMADARI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 42.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KANAPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KANTIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KARANJISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.23,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "KASIASASAN PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.28,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KASIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KERENDIABINDHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KHAJAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "KHARIKUDA MADHYASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 43.28,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KHEDARAPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "KHELAR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOLHANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "KOPINDAPATALIGRAM PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOTHAKUSANGA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44.77,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KUANARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUMARPADA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "KURUKUNDA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUSIKONA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.39,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "KUSUMATHENGA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "LAMBILO PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.3,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "LAXMINRUSINGH ADARSHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.31,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "LOKEIGORADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MAHALO PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MALIASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MANIADIHA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.53,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MITEIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "MOHANDAS PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23.19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "MUKTESWAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 79.98,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "MURUDI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.21,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "NAGABASTA NHS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 38,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NAHANTARA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NAKADIHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "NARAYANI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "NIJOGAKASHOTI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65.12,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NILAKANTHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "NILAKANTHESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 70.23,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "NIMAPARA BAZAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "NUAGAON PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 114,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NUAPATNA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 104,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "NUASANTHA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 42,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ODAGUAN PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.55,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "ODUAL PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "OHALA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "OLAMARA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.44,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "OLANSHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "OLIKANA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "ORAPAT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 82.23,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "PABITRA HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.8,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "PADA GUATIRA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41.65,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PAIKSAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.71,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "PALASUDHA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 108,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PANCHAYAT NODAL UP SCHOOL, PATAPUR",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 22,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "PANCHAYAT NODAL UPS, RENGHALO",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 40.44,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PATAPUR DEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "PIPALIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 0,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "PODANA TUANSAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 50,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.37,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PRACTISING UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.32,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 67.98,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "PURUSANDHA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "PURUSOTTAM NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RAIDBAZAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "RAJARASI PROJECT PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "RATILO UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.31,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RENCH SASAN CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 45,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "RENGHALO HINDU PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.32,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "RESINGA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 69.45,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SADERIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 98.36,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADADEULI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 67.23,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SAHADAPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 17,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.56,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.4,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "SAINSA SASAN CPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29.99,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SALAJANGA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 69.46,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SALANGA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SAMARAISHASAN PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 89.34,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "SAMBHU BHARATIPATNA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33.5,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SANDHAGORADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANDHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANKHAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "SARBAPADA PRADHANSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATAPURI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATKABAD PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.33,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SERAGARH BASTI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 17,
    avg_tS_2to5mins: 6.67,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 24.6,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "SRC NHS BHANTIGRAMA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 114,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "SRIMUKHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.3,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.49,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "SUNINDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "SUNUGORADI NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 44.83,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "TADHANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.65,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.66,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALAANDHIA PROJECT UP SCHOOL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 29.7,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 90,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALATANALA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "TARADPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 10,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TELISAHI PPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "TENTULIGUAN UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "TERUNDIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 17,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 40.49,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "THAKURPATNA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18.88,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "TIHULA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.29,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TORIHANBANDHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31.61,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 48.12,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "TOTASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "TULASIPUR CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "UCHHUPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "UHANGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 94,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "UMAMAHESWARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "VILISASAN PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 2,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "VILLIGRAM PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 42.79,
    no_of_parents_spent_gte45mins: 41,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25.3,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 66,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.89,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.12,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 69.22,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 69.56,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 69.22,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.6,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 69.65,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.89,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 112,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 43.38,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 19,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.9,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.31,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.31,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78.59,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 120,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 48.32,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 45,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.98,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 42,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 44.39,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41.56,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 113,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 99,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 99.99,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.39,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.1,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.43,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.47,
    no_of_parents_spent_gte45mins: 54,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 92,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.61,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.67,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23.1,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 54,
    avg_tS_gte45mins: 119.98,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 15,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 33.34,
    no_of_parents_spent_gte45mins: 45,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32.34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 96,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.65,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.73,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 91,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 40.08,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 118,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 87.98,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.31,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37.98,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 93,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32.4,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 96,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32.21,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 38,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19.7,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.41,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 105,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 23,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.89,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.7,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 54,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 55.96,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.67,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 76.34,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 31,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 30.1,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.34,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.17,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40.65,
    no_of_parents_spent_gte45mins: 31,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 49,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.88,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 62,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 17,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32.34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 53,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 53,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.98,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 115,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 16,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.85,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 10,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 52,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44.6,
    no_of_parents_spent_gte45mins: 37,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 31,
    avg_tS_gte45mins: 51,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.98,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.08,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34.41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 53,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.86,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.56,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 18.3,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 44.1,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 81,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.6,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.8,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.4,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.25,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.3,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 42,
    avg_tS_gte45mins: 89.15,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 18.3,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 70.34,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41.56,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 52,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.17,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 82.18,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.98,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17.72,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 38.45,
    no_of_parents_spent_gte45mins: 45,
    avg_tS_gte45mins: 54,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.32,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41.56,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.1,
    no_of_parents_spent_gte45mins: 37,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 29.9,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 94.98,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 12,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41.69,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23.67,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 108,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.94,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.5,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 104,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.3,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.67,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 165,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.17,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.4,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 42,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 23.45,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 42,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38.61,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 54,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 16,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.2,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 60,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.67,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.5,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43.12,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 88,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 60,
    no_of_parents_spent_gte45mins: 13,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 114,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 51,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26.89,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 23,
    avg_tS_2to5mins: 5.65,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 52,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40.59,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 39,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40.49,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 38.45,
    no_of_parents_spent_gte45mins: 42,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 42.25,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 90,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.7,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 111.11,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 86.19,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 21.2,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 42.37,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 49.45,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 42,
    avg_tS_gte45mins: 57,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 29,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 45,
    avg_tS_gte45mins: 111,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.82,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.31,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 43.31,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 93,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.3,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 27.77,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 51,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27.77,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.17,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 45,
    avg_tS_31to45mins: 42.89,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45.7,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 45.7,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17.39,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 47,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41.56,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 57,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 47,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 26.1,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 51.6,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42.12,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45.7,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.31,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44.6,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38.21,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.3,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.43,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 29.27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.99,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 17,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44.73,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 21.6,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 17.39,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 59.98,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 12,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29.27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 31.67,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 47,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 63.39,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.05,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43.14,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.43,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 56.7,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 18,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41.67,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "JAGABANDHU UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 52.3,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JAINABAD CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 0,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "JAINABAD PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41.34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "JAJALPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.89,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "JANARA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 11,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 58,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JANATA NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 90,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "JAYADURGA NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.78,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "JAYADURGA NODAL UPS, CHAKRAKUDA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "JHAMPULAKUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "JORAKULA PROJECT UPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "JUANLA BADHEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 17.72,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KAHNAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KAJIPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17.72,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 31.67,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 93.26,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KAJIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 29.27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 84.48,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "KALAPANCHANA URDU PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 105,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KALIAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 64,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "KAMADARI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 91,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KANAPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KANTIA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 101,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KARANJISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 84.8,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "KASIASASAN PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 106.11,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KASIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KERENDIABINDHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KHAJAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 66.4,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "KHARIKUDA MADHYASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.56,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 94,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KHEDARAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 76,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "KHELAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOLHANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "KOPINDAPATALIGRAM PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOTHAKUSANGA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KUANARPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 65,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78.45,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUMARPADA NODAL UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 91,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "KURUKUNDA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUSIKONA CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 58,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "KUSUMATHENGA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 42.34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 81,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "LAMBILO PS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "LAXMINRUSINGH ADARSHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "LOKEIGORADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 29.5,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MAHALO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 102,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MALIASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 64.8,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MANIADIHA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 117,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MITEIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 104,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "MOHANDAS PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "MUKTESWAR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 48.28,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "MURUDI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 81,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "NAGABASTA NHS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NAHANTARA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NAKADIHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "NARAYANI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 67.56,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "NIJOGAKASHOTI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 64,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NILAKANTHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.76,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 64,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "NILAKANTHESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 15,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 105,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "NIMAPARA BAZAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87.23,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "NUAGAON PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NUAPATNA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 64,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "NUASANTHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 28,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ODAGUAN PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 81.4,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "ODUAL PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 8,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "OHALA NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "OLAMARA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 49,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 129,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "OLANSHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "OLIKANA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.7,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "ORAPAT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "PABITRA HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 41.34,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 56.7,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "PADA GUATIRA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 97,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PAIKSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "PALASUDHA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 106,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PANCHAYAT NODAL UP SCHOOL, PATAPUR",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "PANCHAYAT NODAL UPS, RENGHALO",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PATAPUR DEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "PIPALIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "PODANA TUANSAHI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PRACTISING UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "PURUSANDHA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "PURUSOTTAM NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 38.89,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 81,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RAIDBAZAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "RAJARASI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "RATILO UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 18,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 26,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RENCH SASAN CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 65,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "RENGHALO HINDU PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 105,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "RESINGA UGUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SADERIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADADEULI PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 25.67,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SAHADAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 19,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 94.98,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "SAINSA SASAN CPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 69.26,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SALAJANGA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SALANGA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 67,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SAMARAISHASAN PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 33.16,
    no_of_parents_spent_gte45mins: 36,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "SAMBHU BHARATIPATNA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25.9,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 82,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SANDHAGORADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANDHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANKHAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "SARBAPADA PRADHANSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 79,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATAPURI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 99,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATKABAD PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 82,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SERAGARH BASTI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "SRC NHS BHANTIGRAMA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 96,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "SRIMUKHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "SUNINDA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "SUNUGORADI NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "TADHANA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALAANDHIA PROJECT UP SCHOOL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 81,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALATANALA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 79,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "TARADPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 49.56,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TELISAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 24.44,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 104,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "TENTULIGUAN UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 38,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "TERUNDIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17.65,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 39,
    avg_tS_gte45mins: 82,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "THAKURPATNA CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18.98,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 37,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "TIHULA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TORIHANBANDHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 18.88,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 67,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "TOTASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 101,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "TULASIPUR CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 79,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "UCHHUPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 67,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.74,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "UHANGA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.98,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33.34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "UMAMAHESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "VILISASAN PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 3,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "VILLIGRAM PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "TARENI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAINSIBADI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 94,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAISHNAVASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.7,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 104,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BAMPAL PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23.4,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "KUSUMESWAR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28.4,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 77.5,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BALAPUR NODAL HS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 23,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "GOPABANDHU JANMAPITH NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "SARANGAJODI UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 28,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "RAICHAKRADHARPUR PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BAINSIBADI",
    school_name: "BIDYADHARPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 78.32,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BALIHUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.5,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "NUASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "BADABHIMADASPUR PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 104,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "GAUDAHAT PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 118,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "ICHHAPUR SAHI PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 116,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "OTARAKERA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SAMANTARAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 99,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "SOLAHALA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 101,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BALIHUDA",
    school_name: "JAYRAMPUR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 66,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHANDARIDANDA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DIHAPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "LUNIADIHAPADA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "BHIMAPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "PAKHIMUNDA PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 20,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 38,
    avg_tS_gte45mins: 61,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "SINGHAKUDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "MADHUBAN NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "DAHANKUMBHARAPADA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 38,
    avg_tS_31to45mins: 43.8,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BHIMPUR",
    school_name: "GOVT.DASBIDYADHARPUR UGHS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 43,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 120,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "MAHABALAPADA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BASTAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 79,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "KABIRAJPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BALISAHI CHANDRAKOTE UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.23,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "HARISANKARPUR NPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BANAPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 110,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "DUNGAR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.12,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 98.34,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "BIRA RAMCHANDRPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BIRARAMACHANDRAPUR",
    school_name: "TALAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BISWANATHPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 62,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BIRAGOBINDPUR NUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21.9,
    no_of_parents_spent_31to45mins: 24,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 81,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BRAHMANANDA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BASUDEIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21.72,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 54,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "UTTAN SAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29.99,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37.21,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "ATHAISH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 19,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 23.45,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "NUAGAON PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 99.23,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PANIVANDAR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.9,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 104,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "PATNASAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 27.76,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 82,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "DUGAL UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 81,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "BISWANATHAPUR",
    school_name: "BADAHAT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 41,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 66,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "MUNIDA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BADAKANJIA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18.88,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BHATTAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28.81,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "HARISARANPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17.88,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "AHAMADPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 23.37,
    no_of_parents_spent_31to45mins: 43,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 82,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "CHAUDABATIA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 17.89,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "JAYAPUR PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "KADUA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "PANCHASAKHA PPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KADUA",
    school_name: "BARAL UGUPS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 34,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 79,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "NILAKANTHESWAR NUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "ALANDAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PADMAPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 36.16,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BISWANATHDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DEULIPARI HARIZANSAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BAGALPUR PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 35.23,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 79,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAIPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.3,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "DUBAPATNA PPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43.12,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 108,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "PANICHATRAPUR PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 32,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 41.45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "KETAKIPATNA NODAL HS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "KETAKIPATNA",
    school_name: "BADATOTA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "HIRANYAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KANHAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 0,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 79,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BATULIPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BASANTAMALA PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "BANAPUR RATNACHIRA PUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "KHANDAYAT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 69.21,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "L.N. NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "CHURALI PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "GOVT ME,ALGUM",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "MALISAHI",
    school_name: "MALISAHI PUPS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 38,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "ATHABATIA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 21.23,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NUASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 43.91,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 81,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BENAKERA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "GHASIBHABANIPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.6,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NAHALA PUPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "NARENDRAPUR PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 10,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "PURUNASOMESWARPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 9,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 61,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "NUA SOMESWARPUR",
    school_name: "BARAGHARIASAHI PPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PANCHASAKHA NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BALARAMPUR GARH PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 82,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "DIPIDEULI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "GENDAMALI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.91,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "NUABUDHAKERA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PATNASAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "PURUNABUDHAKERA PUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MADHIPUR PPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "MAHURA BALISAHI PS",
    no_of_parents_spent_0to1mins: 12,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "PURUNABUDHAKERA",
    school_name: "BASUDEV NUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 9,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.54,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.43,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "MANITIRA SAHI PANASAPATNA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.38,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26.1,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOVT UPGRADED HS,SATYABADI",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 79,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "SATYABADI HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.32,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "GOPALPUR PATNA UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 45,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "BAGULAPARI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 5.72,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.23,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "UGHS MULA ALASA",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.45,
    no_of_parents_spent_16to30mins: 8,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SEVAKSAHI",
    school_name: "TENTULIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.34,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 36,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "PARAHAT UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.67,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 87.3,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.67,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37.98,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BADABILASAHI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.05,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "SRIRAMCHANDRAPUR NUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.4,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "TIKIBILASAHI PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BALUNKESWAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.08,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23.45,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 79,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "CHANDRABRAHMAPUR PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "BHUTUPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26.67,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37.17,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SRI RAMACHANDRAPUR",
    school_name: "NARAKOLIGORADA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 49.29,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "GOVT. UGHS,PARAKENA",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.23,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "CHAKRAPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 119,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADABASANTA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5.5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44.21,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BADHEIKERA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 81,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KALAPAHAD PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45.17,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "KANLAPADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 106,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANLO PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "BILIPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "JAYADURGA NODAL HS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "DIHASAHI PPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 22,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "SUANDO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37.8,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUANLO",
    school_name: "PARAJAPADA PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 106,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISH PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 27,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 38,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "GUALIPARI PUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "ALISHPATNA PPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKALPADA PPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DOKHANDAPUR PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "DAKHINAKERA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 28,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "MARKANDAPADA PUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "BAMBARADA PUPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "SATYABADI",
    cluster: "SUKAL",
    school_name: "SUKAL CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ALAKUNDA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 88.89,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "ALANDA HARIZAN PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 105,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "ALIPINGAL PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "AMARANGA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32.6,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "AMARESWAR GOVT. HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 19,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 45,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "AMBILIHANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "AMPORA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "AMUNIAPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ANANTABASUDEV UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 46,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "ANDHIASAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "ANDHOTI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "ANGARAPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "ANSALO CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.6,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 68.99,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "ARILO PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 51,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "ARISANDHA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 22.31,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "ASHAPURANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "ASHRAM SCHOOL, GARHITAPOKHARI",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45.52,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ATEIHUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 81.11,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "BADAMACHHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 52,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BADARIKILO PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BAHARANA NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 81,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BAKU PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BALANGA ADARSH CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17.77,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BALIPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BALUNKA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 19.27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 37.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "BAMADEIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BAMJHATI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 16.9,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BANDHAMUNDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BANGURUSH PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "BANTUGRAM UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 112,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BARADIHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "BARIMUNDAI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 32.23,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BARUNEI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BASANTAL PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "BASUDEV PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34.45,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "BAULPADA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "BAYABAR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 12,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 34,
    avg_tS_31to45mins: 34.78,
    no_of_parents_spent_gte45mins: 54,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "BERABOI PROJECT UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 16,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BHADARO CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 74,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "BHAGABAN SUNDARA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.34,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 43.76,
    no_of_parents_spent_gte45mins: 36,
    avg_tS_gte45mins: 95,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BHAGABATIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BHANAPUR UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 37,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BHILIDEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "BHOGASALADA PROJECT UPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17.9,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "BHUBANPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 75,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BIANLA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 42.77,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "BIJIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "BILAGORADA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "BILASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 79,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BINAPANI MILITA BIDYAPITHA, PARBATIPUR",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 14,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 41.34,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 52,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "BISEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 26.9,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "BISHNUPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 24,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 39,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 26,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "BISIMATRI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "BISWALSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 54,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 51,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANACHANDAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "BRAHMANAKHANDI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "BUDHEI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 53,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHADHEIGAON NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 39,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHAHATA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 5.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHANARPADA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 0,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "CHHABATIA PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 81,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "CHHANIJANGA NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29.32,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHATAHARA PROJECT PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 86,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "CHHENUA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 16,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 48,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "CHHITIKANA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 69.25,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "CHINGUDIAKANTA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DAHIJANGA PROJECT UPS",
    no_of_parents_spent_0to1mins: 8,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "DALABHANAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "DANDIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "DASPURUSOTTAMPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "DEKHATA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.34,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DENUABASTA PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "DEULIATHENGA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "DHALESWAR UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 26.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "DHAMANTIRA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DHIRAPUR PUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 4.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 89.15,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "DIGHALO URDU PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.82,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "DIHABARI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19.81,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 70.34,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "DINAILO PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "DURGAPUR PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 52,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "EKAMKANA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33.12,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 82.18,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GADIBRAHMA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GANESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 19.91,
    no_of_parents_spent_31to45mins: 28,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GARH ANDHIA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.56,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41.12,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 54,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "GARH ANTUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 46,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "GARHAMARPRASAD PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 69,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "GARHCHANDPUR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 32,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 45,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GARHJHINKIRA JUNUPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "GARHPADANPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 41,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHSAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "GARHTORIHAN PUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 94.98,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "GOPINATHPATANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 14,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "GOPINATHPUR UGUPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 32.71,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "GOVT SAILO NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.23,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "GOVT. HIGH SCHOOL , BAMANAL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.7,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29.98,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 16,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "GOVT. PRIMARY SCHOOL , CHHABATIA",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "GOVT. U.P. SCHOOL , ODAGUAN",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 108,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "GUNDIA LENKASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "HANSAPADA UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "HARIATHENGA PUPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "HARIPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 23,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 24,
    avg_tS_gte45mins: 104,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "HARISPUR PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "HIRAPATANA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "JAGABANDHU UPS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 165,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JAINABAD CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "JAINABAD PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "JAJALPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22.5,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 20,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "JANARA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 54,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "JANATA NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 55.1,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "JAYADURGA NODAL UPS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.6,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "JAYADURGA NODAL UPS, CHAKRAKUDA",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "JHAMPULAKUDA PROJECT UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 7,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "JORAKULA PROJECT UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "JUANLA BADHEISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 15,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 88,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KAHNAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KAJIPATANA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 38.9,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 114,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KAJIPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 51,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "KALAPANCHANA URDU PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KALIAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.9,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 52,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "KAMADARI PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41.2,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 98,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KANAPUR NODAL UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "KANTIA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KARANJISAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 29.1,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 15,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "KASIASASAN PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42.4,
    no_of_parents_spent_gte45mins: 9,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KASIPUR PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "KERENDIABINDHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "KHAJAPUR PROJECT PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "KHARIKUDA MADHYASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 90,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "KHEDARAPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 111.11,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "KHELAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 18,
    avg_tS_gte45mins: 86.19,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOLHANA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "KOPINDAPATALIGRAM PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 25.8,
    no_of_parents_spent_31to45mins: 9,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "KOTHAKUSANGA CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 49.45,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "KUANARPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 35,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 57,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUMARPADA NODAL UPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.5,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 23,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "KURUKUNDA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 111,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "KUSIKONA CPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "KUSUMATHENGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 29,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "LAMBILO PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "LAXMINRUSINGH ADARSHA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 11.3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 7,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "LOKEIGORADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 32.45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MAHALO PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 24.67,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 93,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MALIASAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11.11,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MANIADIHA UGUPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "MATHASAHI PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 23.56,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "MITEIPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 51,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "MOHANDAS PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.8,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "MUKTESWAR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "MURUDI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.11,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 10,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "NAGABASTA NHS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 16.6,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33.8,
    no_of_parents_spent_gte45mins: 39,
    avg_tS_gte45mins: 77.34,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NAHANTARA CPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NAKADIHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 18.32,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 50,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "NARAYANI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40.11,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "NIJOGAKASHOTI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 0,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "NILAKANTHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.23,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 48,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "NILAKANTHESWAR NODAL UP SCHOOL",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12.67,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "NIMAPARA BAZAR PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 57,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "NUAGAON PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHATABANDHA NODAL UPS",
    school_name: "NUAPATNA PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 14,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 51.6,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "NUASANTHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 43,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "ODAGUAN PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 56,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "ODUAL PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 19,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 103,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "OHALA NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "OLAMARA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.6,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 59,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "SAILO NODAL UPS",
    school_name: "OLANSHA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26.89,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "OLIKANA UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 6.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 21.78,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "ORAPAT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44.2,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PABITRA GOVT. HIGH SCHOOL , MANIJANG",
    school_name: "PABITRA HIGH SCHOOL",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8,
    no_of_parents_spent_16to30mins: 18,
    avg_tS_16to30mins: 27.32,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 52.45,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "PADA GUATIRA PROJECT PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 21.23,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PAIKSAHI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 4.6,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "PALASUDHA PROJECT PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 83,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PANCHAYAT NODAL UP SCHOOL, PATAPUR",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28.3,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 69.15,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "PANCHAYAT NODAL UPS, RENGHALO",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 29.99,
    no_of_parents_spent_31to45mins: 19,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 39,
    avg_tS_gte45mins: 69.34,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "PATAPUR DEULI PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 39.9,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "PIPALIA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 59.98,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "CHHANIJANGA NODAL UPS",
    school_name: "PODANA TUANSAHI PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 12.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "GOPINATHPATNA PS",
    school_name: "PRACTISING UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 3,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 8,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "PURUSANDHA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 23.8,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "PURUSOTTAM NODAL UPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 2.9,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 47,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RAIDBAZAR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 3.3,
    no_of_parents_spent_16to30mins: 12,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 63.39,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "RAJARASI PROJECT PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 3.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 55,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "RATILO UGUPS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 6.3,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 10,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "RENCH SASAN CPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 21,
    avg_tS_31to45mins: 43,
    no_of_parents_spent_gte45mins: 44,
    avg_tS_gte45mins: 88.4,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "RENGHALO HINDU PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 14.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 78,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "RESINGA UGUPS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 20,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 17,
    avg_tS_gte45mins: 73,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SADERIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10.3,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 56,
    no_of_parents_spent_gte45mins: 6,
    avg_tS_gte45mins: 60,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.54,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 45,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 80,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "OILIKANA UG UPS",
    school_name: "SAGADADEULI PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 76,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SAHADAPADA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 28.8,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 42,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 52.3,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "SAINSA SASAN CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 27,
    avg_tS_gte45mins: 49,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SALAJANGA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 4.2,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAMANAL PS",
    school_name: "SALANGA PS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 25.67,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 44,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHANAPUR UGUPS",
    school_name: "SAMARAISHASAN PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.5,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 34,
    avg_tS_gte45mins: 58,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "SAMBHU BHARATIPATNA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 11,
    avg_tS_gte45mins: 90,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SANDHAGORADA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 9,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37.89,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANDHAPUR PROJECT UPS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 27,
    no_of_parents_spent_31to45mins: 22,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 14,
    avg_tS_gte45mins: 65,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "SANKHAPADA PS",
    no_of_parents_spent_0to1mins: 9,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 67,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "SARBAPADA PRADHANSAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 7.9,
    no_of_parents_spent_16to30mins: 21,
    avg_tS_16to30mins: 25.9,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 5,
    avg_tS_gte45mins: 89,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATAPURI PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 15,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 87,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "SATKABAD PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "SERAGARH BASTI PROJECT PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 5,
    avg_tS_2to5mins: 11.5,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 0,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 93.26,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "SRC NHS BHANTIGRAMA",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 30,
    no_of_parents_spent_31to45mins: 4,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 84.48,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "SRIMUKHA PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 12.2,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 43.45,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 105,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "KOTHAKUSANGA PS",
    school_name: "SUNINDA PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 14.1,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 39,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 64,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "RENCH SASAN PS",
    school_name: "SUNUGORADI NODAL UPS",
    no_of_parents_spent_0to1mins: 6,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 6.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 17,
    avg_tS_31to45mins: 31,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 91,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "TADHANA PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 11.1,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 27.8,
    no_of_parents_spent_31to45mins: 1,
    avg_tS_31to45mins: 34,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 85,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALAANDHIA PROJECT UP SCHOOL",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 4.4,
    no_of_parents_spent_16to30mins: 4,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 36,
    no_of_parents_spent_gte45mins: 21,
    avg_tS_gte45mins: 101,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BAPUJI NODAL UPS, NAGABASTA",
    school_name: "TALATANALA PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 29,
    no_of_parents_spent_31to45mins: 5,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 84.8,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "TARADPADA PS",
    no_of_parents_spent_0to1mins: 0,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 10,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 25,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 33,
    no_of_parents_spent_gte45mins: 12,
    avg_tS_gte45mins: 106.11,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TELISAHI PPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 11,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 14,
    avg_tS_31to45mins: 35.56,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 84,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "ODAGUAN PROJECT UPS",
    school_name: "TENTULIGUAN UGUPS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 9.8,
    no_of_parents_spent_16to30mins: 7,
    avg_tS_16to30mins: 28,
    no_of_parents_spent_31to45mins: 11,
    avg_tS_31to45mins: 32.5,
    no_of_parents_spent_gte45mins: 32,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DIGHALO NODAL UPS",
    school_name: "TERUNDIA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 2,
    avg_tS_2to5mins: 13.4,
    no_of_parents_spent_16to30mins: 11,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 18,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 66.4,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "THAKURPATNA CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 7.5,
    no_of_parents_spent_16to30mins: 32,
    avg_tS_16to30mins: 20.25,
    no_of_parents_spent_31to45mins: 12,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 30,
    avg_tS_gte45mins: 94,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "AMARANG UGUPS",
    school_name: "TIHULA PS",
    no_of_parents_spent_0to1mins: 5,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 3,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 24.44,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 38,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHAGABATIPUR NODAL UPS",
    school_name: "TORIHANBANDHA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 4,
    avg_tS_2to5mins: 9.2,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 32,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 68,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BALANGA GOVT. UPS",
    school_name: "TOTASAHI PROJECT PS",
    no_of_parents_spent_0to1mins: 7,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 8.4,
    no_of_parents_spent_16to30mins: 3,
    avg_tS_16to30mins: 17.65,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 0,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 71,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "TULASIPUR PS",
    school_name: "TULASIPUR CPS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 8.9,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 18.98,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 40.5,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 77,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "PANCHAYAT UPS, UCHHUPUR",
    school_name: "UCHHUPUR PS",
    no_of_parents_spent_0to1mins: 1,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 7,
    avg_tS_2to5mins: 14.8,
    no_of_parents_spent_16to30mins: 6,
    avg_tS_16to30mins: 23.33,
    no_of_parents_spent_31to45mins: 8,
    avg_tS_31to45mins: 40,
    no_of_parents_spent_gte45mins: 4,
    avg_tS_gte45mins: 70,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "DHALESWAR UG UPS",
    school_name: "UHANGA PS",
    no_of_parents_spent_0to1mins: 3,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 5,
    avg_tS_16to30mins: 18.88,
    no_of_parents_spent_31to45mins: 6,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 2,
    avg_tS_gte45mins: 78.45,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BHADARO PS",
    school_name: "UMAMAHESWARPUR PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 12,
    no_of_parents_spent_16to30mins: 2,
    avg_tS_16to30mins: 21,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 31.67,
    no_of_parents_spent_gte45mins: 1,
    avg_tS_gte45mins: 91,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "MITEIPUR PROJECT UPS",
    school_name: "VILISASAN PS",
    no_of_parents_spent_0to1mins: 2,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 1,
    avg_tS_2to5mins: 7,
    no_of_parents_spent_16to30mins: 1,
    avg_tS_16to30mins: 26,
    no_of_parents_spent_31to45mins: 2,
    avg_tS_31to45mins: 37,
    no_of_parents_spent_gte45mins: 3,
    avg_tS_gte45mins: 72,
  },
  {
    month: 8,
    week: 4,
    district: "PURI",
    block: "NIMAPADA",
    cluster: "BISHNUPUR NODAL UPS",
    school_name: "VILLIGRAM PS",
    no_of_parents_spent_0to1mins: 4,
    avg_tS_0to1mins: 0,
    no_of_parents_spent_2to5mins: 6,
    avg_tS_2to5mins: 14.5,
    no_of_parents_spent_16to30mins: 17,
    avg_tS_16to30mins: 22,
    no_of_parents_spent_31to45mins: 13,
    avg_tS_31to45mins: 41,
    no_of_parents_spent_gte45mins: 19,
    avg_tS_gte45mins: 58,
  },
];
