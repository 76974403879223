import React from "react";
import NavigationFellow from "../../Components/Navigation/NavigationFellow";

const FellowRoot = () => {
  return (
    <>
      <NavigationFellow />
    </>
  );
};

export default FellowRoot;
