import React from "react";

const Dashboard = () => {
  return (
    <>
      <div style={{ marginTop: "300px", fontSize: "30px" }}>
        <b>Anganwadi Dashboard Under Maintainance</b>
      </div>
    </>
  );
};

export default Dashboard;
